import * as BOX from "./Box";
import * as BOXSession from "./BoxSession";
export const VAR   = "X";

export function profGetUserUrl(){return BOX.URL_SERVEUR+"/rest/user/eleves/"+BOXSession.getJsonParse('sessionUser').login;}    
export function profGetUserRequest(){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'GET',headers: myHeaders,redirect: 'follow'};
}

export function profGetUserExoUrl(idEleve){return BOX.URL_SERVEUR+"/rest/exercice/eleve/exercices/"+idEleve}    
export function profGetUserExoRequest(){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'GET',headers: myHeaders,redirect: 'follow'};
}



export function profGetExoUrl(){return BOX.URL_SERVEUR+"/rest/exercice/exercices/ecole/"+BOXSession.getJsonParse('sessionUser').login;}    
export function profGetExoRequest(){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'GET',headers: myHeaders,redirect: 'follow'};
}



export function profGetPartieUrl(){return BOX.URL_SERVEUR+"/rest/partie/prof/"+BOXSession.getJsonParse('sessionUser').login;}    
export function profGetPartieRequest(){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'GET',headers: myHeaders,redirect: 'follow'};
}

export function profSaveGroupeXUrl(){  return BOX.URL_SERVEUR+"/rest/parametre/groupe/save"}    
export function profSaveGroupeXRequest(mode,id,nom){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    var body = JSON.stringify({"id":id,"nom":nom,"mode":mode,"prof":BOXSession.getJsonParse('sessionUser').login,"statutSelect":""});
    return  {method: 'POST',headers: myHeaders,body: body,redirect: 'follow'};    
}

export function profSaveNewExoUrl(){  return BOX.URL_SERVEUR+"/rest/partie/action/json"}    
export function profSaveNewExoRequest(req){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    //var body = JSON.stringify({"id":id,"nom":nom,"mode":mode,"prof":BOXSession.getJsonParse('sessionUser').login,"statutSelect":""});
    return  {method: 'POST',headers: myHeaders,body: JSON.stringify(req),redirect: 'follow'};    
}

export function profSaveNewPartieUrl(){  return BOX.URL_SERVEUR+"/rest/partie/monelevedeseches/save"}    
export function profSaveNewPartieRequest(req){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'POST',headers: myHeaders,body: JSON.stringify(req),redirect: 'follow'};    
}

export function profSaveEcoleUrl(){  return BOX.URL_SERVEUR+"/rest/user/ecole/save/monelevedeseches"}    
export function profSaveEcoleRequest(req){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'POST',headers: myHeaders,body: JSON.stringify(req),redirect: 'follow'};    
}






export function profDeleteGroupeExerciceUrl(idGroupeExercice){return BOX.URL_SERVEUR+"/rest/exercice/deleteGroupeExerciceX/"+idGroupeExercice+"/"+BOXSession.getJsonParse('sessionUser').login}    
export function profDeleteGroupeExerciceRequest(){
    let myHeaders = new Headers();
    myHeaders.append("X-AMOS-TOKEN", BOXSession.getString('sessionToken'));
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'GET',headers: myHeaders,redirect: 'follow'};
}






export function inscriptionUrl(){  return BOX.URL_SERVEUR+"/rest/user/eleve/save"}    
export function inscriptionRequest(body){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'POST',headers: myHeaders,body: body,redirect: 'follow'};    
}

export function loginUrl(){  return BOX.URL_SERVEUR+"/rest/user/login"}    
export function loginRequest(body){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    return  {method:'POST',headers:myHeaders,body:body,redirect:'follow'};
    
}

export function inscriptionClasseUrl(){return BOX.URL_SERVEUR+"/rest/user/classes/monecole"}    
export function inscriptionClasseRequest(){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    return  {method: 'GET',headers: myHeaders,redirect: 'follow'};
}


export function resetPwdUrl(){  return BOX.URL_SERVEUR+"/rest/user/reinitPW"}    
export function resetPwdRequest(body){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    return  {method:'POST',headers:myHeaders,body:body,redirect:'follow'};
}

export function contactUrl(){  return BOX.URL_SERVEUR+"/rest/parametre/contact"}    
export function contactRequest(body){
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    return  {method:'POST',headers:myHeaders,body:body,redirect:'follow'};
}

