import React from 'react'
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';

export default function MenuPage() {
  return (
    <div>
      <Link className="btn btn-primary" to="/">Home</Link>&nbsp; 
      <Link className="btn btn-primary" to="/user/stephanebrunet">User</Link>&nbsp;
      <Button variant="primary" className="mr-2">test bouton Primary</Button>
      <hr/>
      
    </div>
  )
}
