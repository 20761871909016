import React from 'react'
import {Redirect} from 'react-router-dom';
import ResetPasswordPageConfirm from './ResetPasswordPageConfirm'
import Message from '../factory/Message'
//import * as BOXSession from "../factory/BoxSession";
import * as BOXApi from "../factory/BoxApi";
import '../factory/factory.css';
import '../factory/animate.css'; 

export default  class ResetPasswordForm  extends React.Component {
  constructor(props) {
    super(props);
    this.submitHandler   = this.submitHandler.bind(this);
    this.userouemail     = React.createRef();
    this.state = { 
      ecran2        : false,
      userouemail   : '',
      messageTexte  : '',messageTemps:0,messageStyle:"info",
      redirectToHome: false  };
  }



  myChangeHandler = (event) => {
    if(event.target.name === "userouemail")    { this.setState({userouemail: event.target.value}); }
  }


  submitHome = (event) => {
    this.setState(() => ({ redirectToHome: true }))
  }


  submitHandler = (event) => {
    //this.setState(() => ({ ecran2: true }))
    //return
    event.preventDefault();
    this.setState( {messageTexte: ''})
    console.log('trt submit' + this.state.messageTexte);
    if(this.state.userouemail === '' )  {
      this.setState({ messageTexte: "Ooops, Compte ou email obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
      this.userouemail.current.focus();
      return;   
    }
   
    console.log("traitement reinit Password" + BOXApi.resetPwdUrl());
    fetch(BOXApi.resetPwdUrl(), BOXApi.resetPwdRequest(this.state.userouemail))
    .then(response => response.text())
    .then(result => this.trtSubmit(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }
  
  errorSubmit = (error) =>{
    if(error  ){
      this.setState(() => ({ messageTexte: "Ooops, erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
  }
  
  trtSubmit = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json  && json.codeRetour && json.codeRetour === "OK"){
      // TRAITEMENT REPONSE SERVEUR OK
      this.setState(() => ({ ecran2: true }))
    }else{
      // TRAITEMENT REPONSE SERVEUR KO
      try {
        this.setState(() => ({ messageTexte: 'Oooops '+json.reponse+' !!!' , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      } catch (error) {
        this.setState(() => ({ messageTexte: "Ooops, erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      }
    }
  }
 



  render() {
    if (this.state.redirectToHome) {  return <Redirect to='/home' />   }
    if (this.state.ecran2)         {  return <ResetPasswordPageConfirm/>   }
    return (
      <div className='text-center00'>
        <div className="fondblanc bordure fdc  animated pulse rw50" >
            <h4>Demande d'un nouveau mot de passe</h4>
            <form className="m5" onSubmit={this.submitHandler}>
              <span className="text-left">Indiquez votre compte ou email et vous recevrez un nouveau mot de passe.</span>
              <div className="text-left">{this.state.userouemail === '' ? <br/> : 'Compte ou email'}</div>
              <div className="">         <input className="w100" type="text" name="userouemail"     ref={this.userouemail}     onChange={this.myChangeHandler} placeholder="Compte ou email"/></div>
              <br/><br className="formatnormal"/>
              <div className="row">
                <div className="col text-center">
                  <span  className={'text-center rondreverse m5 m2'}  onClick={this.submitHome}> Annuler </span>
                </div>
                <div className="col text-center ">
                  <span className={'text-center rond m5'}  onClick={this.submitHandler}> Valider </span>
                </div>
              </div>        
            </form>

            <div className="text-center">
                <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            </div>
        </div>
      </div>
    )
  }
}
