import React from 'react'
//import { Link } from 'react-router-dom'
import {Redirect} from 'react-router-dom';
import '../factory/factory.css';



export default class BlocVideo extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleShowVideo = this.handleShowVideo.bind(this);
      this.state           = {redirectToVideo: false  };
    }
    handleShowVideo() {
        console.log("test");
        sessionStorage.setItem('sessionVideoLien',   this.props.lien);
        sessionStorage.setItem('sessionVideoRetour', this.props.retour);
        sessionStorage.setItem('sessionVideoTitre',  this.props.titre);
        this.setState(() => ({ redirectToVideo: true }))
    }

    render() {
    if (this.state.redirectToVideo) {  return <Redirect to='/video' />   }
    return (
        <div className={this.props.myClassName} onClick={this.handleShowVideo}>

            {this.props.fontawesome && this.props.fontawesome !== 'fa fa-info-circle' && this.props.fontawesome !== 'play' ?<i class="m2px fas fa-lightbulb"></i> :'' }
            {this.props.fontawesome === 'play' ?<i class="m2px fas fa-play fa-2x"></i> :'' }

            {this.props.titre} 
        </div>    
    )
  }
}