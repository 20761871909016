import React from 'react'
import Message from '../factory/Message'
import * as BOXApi     from "../factory/BoxApi";
import * as BOXSession from "../factory/BoxSession";
import '../factory/factory.css';


export default  class DeleteExo  extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idExo         : 0,  
      messageTexte  : '',
      messageTemps  : 0,
      messageStyle  : "info",
      redirectToHome: false  };
  }



  mySubmitHandler = (event) => {
    event.preventDefault();
    this.setState(() => ({ message: ""}))
    console.log("trt Del Exo " + BOXApi.profDeleteGroupeExerciceUrl(this.props.exo.idGroupeExercice));
    fetch(BOXApi.profDeleteGroupeExerciceUrl(this.props.exo.idGroupeExercice), BOXApi.profDeleteGroupeExerciceRequest())
    .then(response => response.text())
    .then(result => this.trtSubmit(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
    
  }
  
  errorSubmit = (error) =>{
    alert('error ' + error)
    if(error  ){this.setState(() => ({ messageTexte: "Erreur réseau"  }))}
  }
  
  trtSubmit = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json  && json.codeRetour && json.codeRetour === "OK"){
       this.setState(() => ({idExo:this.props.exo.idGroupeExercice, messageTexte: 'Exercice retiré', messageStyle : 'infoOrange',  messageTemps : Number(Date.now()+3*1000)  } ));
       this.props.parentMethod();
    }else{
      this.setState(() => ({ message: "login invalide"  })) 
    }
  }


  render() {
    return (
        <div className="fondblanc text-left m2 " >
          <br/>
          <h4>Suppression de l'exercice "{this.props.exo.texte === undefined ? '' :  this.props.exo.texte.split(',')[1]}"</h4>
          <br/><br/>
          <div className="fondblanc text-center m2" >
            {this.state.idExo !== this.props.exo.idGroupeExercice
              ?<span className={'text-center rond m2'}  onClick={this.mySubmitHandler}> <i class="fas fa-check"></i> Valider </span>
              :''
            }
          </div>
          <div className="text-center">
            <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
          </div>


           {/*
           <div>
             idGroupeExercice: {this.props.exo.idGroupeExercice}
             idGroupe: {this.props.exo.idGroupe}
             id: {this.props.exo.id}
            </div> 
          */}
          <br/><hr/>

      </div>
    )
  }
}
