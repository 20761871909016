import React from 'react'
import BlocHome from '../main/BlocHome';
import BlocVideo  from '../video/BlocVideo'
import {Link} from 'react-router-dom'
import './factory.css'; 

export default function HomeModule() {
  return (
    <div className="row">
        <div className="col">
              <BlocHome 
                  titre="Support Vidéo"
                  texte="De nombreuses vidéos pour apprendre le mouvement des pièces et différents concepts du jeu d'échecs."
                  titreyoutube="Présentation du module des vidéos."
                  lien="https://youtu.be/veylzl_Jq3Q#t=1m37s"
                  retour="/home"
              />
        </div>
        <div className="col1 w1px fondcolorAmmande"></div>
        <div className="col">
              <BlocHome 
                  titre="Support pédagogique"
                  texte="Chaque enseignant peut définir son plan de travail avec des exercices planifiés tous les jours."
                  titreyoutube="Présentation du module des exercices."
                  lien="https://youtu.be/veylzl_Jq3Q#t=5m09s"
                  retour="/home"
              />
        </div>
        <div className="col1 w1px fondcolorAmmande"></div>
        <div className="col">
              <BlocHome 
                  titre="Support matériel "
                  texte="Un module partie permet à la classe de jouer des parties collectivement ou individuellement."
                  titreyoutube="Présentation du module des parties."
                  lien="https://youtu.be/veylzl_Jq3Q#t=9m18s"
                  retour="/home"
              />
        </div>
        <div className="col1 w1px fondcolorAmmande"></div>
        <div className="col">

                    <div className="carre text-center ">
                        <div className="fdr text-center">
                        <div className="text-right">
                            <BlocVideo myClassName="pointer mailto00 text-right"  fontawesome="play" titre="&nbsp;" lien="https://youtu.be/veylzl_Jq3Q" retour="/main"/>                          
                        </div>
                            <BlocVideo myClassName="pointer mailto00"  fontawesome="" titre="&nbsp;Petite vidéo pour tout comprendre !" lien="https://youtu.be/veylzl_Jq3Q" retour="/main"/>  
                        </div>    
                    </div>
                    <div className="h4px"></div>

                    
                    <div  className="carre amande pointer text-center fontblanc noeffetsurvol"  > 
                        <Link className="fontblanc noeffetsurvol"  to="/contact">
                            <i class="fa fa-envelope" aria-hidden="true"></i> Vous avez des questions ? <br/>- Nous contacter
                        </Link> 
                    </div>
                    <div className="h4px"></div>
                    <div  className="carre amande pointer text-center fontblanc noeffetsurvol" > 
                            Vous préférez le téléphone ? <br/>
                            <i class="fa fa-phone" aria-hidden="true"></i> 07 68 33 16 15
                    </div>

        </div>

</div>

  )
}
