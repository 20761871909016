import * as BOX from "./Box";

export const VAR   = "x";

export function  openSession(login,json){ 
    console.log(">>>>>>>>  OpenSession: " +  BOX.URL_SERVEUR + " VIDEOS " +  BOX.VIDEOS);
    //sessionStorage.setItem('sessionUser',    login);
    sessionStorage.setItem('sessionUser',    JSON.stringify(json.user));
    sessionStorage.setItem('sessionToken',   '123');
    sessionStorage.setItem('sessionVideos',  BOX.VIDEOS);
    sessionStorage.setItem('sessionGroupes', JSON.stringify(json.groupes));
    sessionStorage.setItem('sessionExos',    JSON.stringify(json.exercices));
};

export function  getString(param){ 
    //console.log("BoxSession.getString("+param+") " + sessionStorage.getItem(param) );
    return sessionStorage.getItem(param);
};

export function  getJsonParse(param){ 
    //console.log('getJsonParse ' + param);
    //console.log("BoxSession.getJsonParse("+param+") " + sessionStorage.getItem(param) );
    var x = JSON.parse(sessionStorage.getItem(param));
    if(x === null) {
        if(param === 'sessionGroupes'){return[];}
        if(param === 'sessionEleves'){return[];}
        return {};
    } 
    return JSON.parse(sessionStorage.getItem(param));
};


