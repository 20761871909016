import React from 'react'
import {Link} from 'react-router-dom'




export default function LoginPageInactif(props) {
  return (
    <div className="fdrr">
      <div className="fondblanc rw50 bordure animated fadeInLeft">
        <div className="w100 text-right">
            
            <Link  className="fa fa-times-circle rondclose" to="/main"></Link>&nbsp;

        </div>
        <div className="m2">
        Votre compte <b>{props.login}</b> n'est pas activé, veuillez reprendre le mail d'activation envoyé le <b>{props.date}</b> dans votre boite mail <b>{props.mail}</b>.
        Vous trouverez un lien pour activer votre profil !!!
            <br/>
            <div className="fdr m2">
                  <div className="fondcolor"> &nbsp;<br/></div>
                  <div className="m2">
                  Si vous ne trouvez pas ce mail, veuillez vérifier votre dossier des courriers indésirables.


                  </div>
            </div>
        </div>

      </div>

    </div>
  )
}
