import React from 'react'
import Message from '../factory/Message'
import * as BOXApi from "../factory/BoxApi";
import '../factory/factory.css';

export default  class AddClasse  extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      nom           : '',
      messageTexte  : '',
      messageTemps  : 0,
      messageStyle  : "info",
      redirectToHome: false  };
  }

  myChangeHandler = (event) => {
    if(event.target.name === "nom"){ this.setState({nom: event.target.value}); }
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    debugger
    this.setState(() => ({ message: ""}))
    if(this.state.nom === ''){
      this.setState(() => ({ messageTexte: "Ooops, Nom obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    console.log("trt ajout Groupe " + BOXApi.profSaveGroupeXUrl());
    fetch(BOXApi.profSaveGroupeXUrl(), BOXApi.profSaveGroupeXRequest('ADD','',this.state.nom))
    .then(response => response.text())
    .then(result => this.trtSubmit(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }
  
  errorSubmit = (error) =>{
    alert('error ' + error)
    if(error  ){this.setState(() => ({ message: "Erreur réseau"  }))}
  }
  
  trtSubmit = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json.response  && json.response.codeRetour && json.response.codeRetour === "OK"){
       this.setState(() => ({ nom: "",messageTexte: "classe " + this.state.nom + " enregistrée ", messageStyle : 'infoOrange',  messageTemps : Number(Date.now()+3*1000)  } ));
       sessionStorage.setItem('sessionGroupes', JSON.stringify(json.groupes));
       this.props.parentMethod();
    }else{
      this.setState(() => ({ message: "login invalide"  })) 
    }
  }


  render() {
    return (
        <div className="fondblanc " >
            <form onSubmit={this.mySubmitHandler}>
              <div className="m2 text-left">
                <div className="m2">{this.state.nom === '' ? <br/> : 'Nom de la classe'}</div>
              </div>
              <div className="m2">
                <input className="w100" type="text" name="nom" value={this.state.nom} placeholder="Nom de la classe"   onChange={this.myChangeHandler} />
              </div>
              <br/><br/>
              <span className={'text-center rond m2'}  onClick={this.mySubmitHandler}> <i class="fas fa-save"></i> Save</span>
            </form>
            <div>
            <br/>
            <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            </div>
            <br/><hr/>
      </div>
    )
  }
}
