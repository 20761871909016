import React from 'react'
import Message from '../factory/Message'
import * as BOXApi     from "../factory/BoxApi";
import * as BOXSession from "../factory/BoxSession";
import '../factory/factory.css';
import '../main/MainPage.css';


export default  class AddExo  extends React.Component {

  constructor(props) {
    super(props);
    this.dateDebut     = React.createRef();
    this.dateFin       = React.createRef();
    this.state = {
      displayAction : '',
      displayWait   : 'nodisplay',
      displayEnd    : 'nodisplay',
      classe        : '',
      dateDebut     : '',
      dateFin       : '',
      nbrExo        : '1',
      niveau        : '',
      messageTexte  : '',
      messageTemps  : 0,
      messageStyle  : "info",
      redirectToHome: false  };
  }


  myChangeHandler = (event) => {
    if(event.target.name === "dateDebut"){ this.setState({dateDebut: event.target.value}); }
    if(event.target.name === "dateFin")  { this.setState({dateFin: event.target.value}); }
    if(event.target.name === "nbExo")    { this.setState({nbrExo: event.target.value}); }
    if(event.target.name === "classe")   { this.setState({classe: event.target.value}); }
    if(event.target.name === "niveau")   { this.setState({niveau: event.target.value}); }
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    this.setState(() => ({ message: ""}))
    if(this.state.dateDebut === ''){
      this.setState(() => ({ messageTexte: "Ooops, valeur obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      this.dateDebut.current.focus();
      return;
    }
    if(this.state.dateFin === ''){
      this.setState(() => ({ messageTexte: "Ooops, valeur obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      this.dateFin.current.focus();
      return;
    }
    debugger;
    let idClasse =  this.state.classe;
    if(this.state.classe === '' && this.props.groupes.length>0 ){
      console.log("groupe1:" + this.props.groupes[0].id )
      idClasse = this.props.groupes[0].id ; 
    }  
    
    console.log("trt ajout new Exo " + BOXApi.profSaveNewExoUrl());
    let pc1 = 80;
    let pc2 = 20;
    let pc3 = 0;
    let pc4 = 0;
    let pc5 = 0;
    if(this.state.niveau === "2" ){
      pc1=15;pc2=35;pc3=25;pc4=20;pc5=5;
    }
    if(this.state.niveau === "3" ){
      pc1=5;pc2=15;pc3=40;pc4=25;pc5=15;
    }
    if(this.state.niveau === "4" ){
      pc1=0;pc2=10;pc3=20;pc4=30;pc5=40;
    }


    let req = {"action":"addExoToGroupe",
        "idGroupe":    idClasse,
        "dateDebut":   this.state.dateDebut,
        "dateFin":     this.state.dateFin,
        "nbExoParJour":this.state.nbrExo,
        "theme":       "",
        "pc1": pc1,
        "pc2": pc2,
        "pc3": pc3,
        "pc4": pc4,
        "pc5": pc5,
        "prof": BOXSession.getJsonParse('sessionUser').login,
    };
    // On affiche veuillez patienter
    this.setState(() => ({displayAction : 'nodisplay',displayWait   : 'maintitre'  }))





    fetch(BOXApi.profSaveNewExoUrl(), BOXApi.profSaveNewExoRequest(req))
    .then(response => response.text())
    .then(result => this.trtSubmit(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
    
  }
  
  errorSubmit = (error) =>{
    alert('error ' + error)
    if(error  ){this.setState(() => ({message:"Erreur réseau",displayAction:'',displayWait:'nodisplay'}))}
  }
  
  trtSubmit = (json) =>{
    debugger;
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json  && json.codeRetour && json.codeRetour === "OK"){
       this.setState(() => ({displayAction:'',displayWait:'nodisplay',messageTexte: json.errors[0], messageStyle : 'infoOrange',  messageTemps : Number(Date.now()+3*1000)  } ));
       this.props.parentMethod();
    }else{
      this.setState(() => ({ message: "login invalide",displayAction:'',displayWait:'nodisplay'  })) 
      this.setState(() => ({ messageTexte: json.errors[0], messageStyle : 'erreur',  messageTemps : Number(Date.now()+4*1000)  } ));
    }
  }


  render() {
    return (
        <div className="fondblanc " >

            <div className={this.state.displayWait}  >
                <br/><br/>Veuillez patienter traitement en cours.<br/><br/><br/><br/>
            </div>
            <div className={this.state.displayEnd}  >
                {this.state.messageTexte}
            </div>
            <div className={this.state.displayAction}  >
              <div className="maintitre"><i class="fas fa-chess-king"></i>Ajouter des exercices</div>

              <form onSubmit={this.mySubmitHandler}>

                <div className="m2 text-left">Entre</div>
                <div className="m2">
                  <input className="w100" type="date" name="dateDebut" ref={this.dateDebut}    onChange={this.myChangeHandler} />
                </div>
                <div className="m2 text-left">et</div>
                <div className="m2">
                  <input className="w100" type="date" name="dateFin"   ref={this.dateFin}    onChange={this.myChangeHandler} />
                </div>
                <div className="m2 text-left">Nombre d'exercice par jour</div>
                <div className="m2">
                  <select className="w100 myform"  name="nbExo"    onChange={this.myChangeHandler}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                <div className="m2 text-left ">Classe</div>
                <div className="m2">
                  <select className="w100 myform"  name="classe"    onChange={this.myChangeHandler}>
                    {this.props.groupes.map((groupe) => <option value={groupe.id}>{groupe.nom}</option> ) }
                  </select>
                </div>


                <div className="m2 text-left">Niveau</div>
                <div className="m2">
                  <select className="w100 myform"  name="niveau"    onChange={this.myChangeHandler}>
                    <option value="1">Débutant</option>
                    <option value="2">Intermédiaire</option>
                    <option value="3">Confirmé</option>
                    <option value="4">Expert</option>
                  </select>
                </div>
                <br/><br/>
                <span className={'text-center rond m2'}  onClick={this.mySubmitHandler}> <i class="fas fa-save"></i> Enregistrer</span>
                </form>
            </div>        


            <div>
                 <br/> 
                 <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            </div>
            <br/>
            <hr/>  
      </div>
    )
  }
}
