import React from 'react'
import {Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom'
import * as BOX from "../factory/Box";
import * as BOXSession from "../factory/BoxSession";
import 'bootstrap/dist/css/bootstrap.min.css';
import './factory.css';
import logo from '../factory/img/logo.png';


export default  class HeaderPage  extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      sessionUser   : BOXSession.getString('sessionUser'),
      redirectToMain: false 
      };
    }
  
    componentDidMount() {
      console.log(">>HeaderPage componentDidMount: "+this.state.sessionUser + "|" + BOXSession.getString('sessionUser'))
      if(this.state.sessionUser !== null ){
        this.setState(() => ({ redirectToMain: true }))
      }   
    }
 
  render(){
    if (this.state.redirectToMain) {  return <Redirect to='/main' />   }
    return (
      <div>
        <div className="formatnormal fondBlanc ombreb">
          <Link className="logo"  to="/"> 
          <img src={logo} width="50" height="50"></img>
          
          <span className="logopng00"> </span> {BOX.NOM_SITE} </Link>&nbsp; 
          <div className="float-right m5px">
            <div className="m5px">
            {this.state.sessionUser}
            {/*
            <Link className="btn btn-outline-secondary" to="/login">Se connecter</Link>&nbsp;
            <Link className="btn btn-secondary" to="/inscription">Inscription</Link>&nbsp;
            */}

            <Link className="rondreverse m5" to="/login">Se connecter</Link>&nbsp;
            <Link className="rond m5 fontblanc" to="/inscription">Inscription</Link>&nbsp;
            </div>


          </div> 
        </div>
        <div className="formatmobile fondBlanc ombreb">
          <Link className="logo"  to="/"> <span className="logopng00"> </span> {BOX.NOM_SITE} </Link>
          <hr/>
        </div>

      </div>
  )
}

}
 