//http://www.elearnchess.com?i=12365186237&u=4608213968184410112&action=activation&hashcode=pruthadi&r=12365186237

import React from 'react'
import {Route,Switch}    from 'react-router-dom'
import HomePage          from './pages/main/HomePage'
import LoginPage         from './pages/main/LoginPage'
import InscriptionPage   from './pages/main/InscriptionPage'
import ResetPasswordPage from './pages/main/ResetPasswordPage'
import ContactPage       from './pages/main/ContactPage'
import MainPage          from './pages/main/MainPage'
import ActivationPage    from './pages/main/ActivationPage'
import TestPage          from './pages/factory/TestPage'
import VideoPage         from './pages/video/VideoPage'
import UserPage          from './pages/UserPage'


export default function App() {
  return (

    <Switch>
      <Route exact path="/"                     component={HomePage} />
      <Route path="/login"                      component={LoginPage} />  
      <Route path="/inscription"                component={InscriptionPage} />  
      <Route path="/resetPassword"              component={ResetPasswordPage} />  
      <Route path="/contact"                    component={ContactPage} />  
      <Route path="/main"                       component={MainPage} /> 
      <Route path="/video"                      component={VideoPage} />
      <Route path="/test"                       component={TestPage} /> 
      <Route path="/user/:userName"             component={UserPage} />
      <Route path="/activation/:user/:hashcode" component={ActivationPage} />
      <Route component={HomePage} />
    </Switch>
  )
}
