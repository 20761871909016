import React from 'react'
import {Link} from 'react-router-dom'
import HeaderPage from '../factory/HeaderPage'
import HomeModule from '../factory/HomeModule'
import HomeMobile from '../factory/HomeMobile'
import Form       from './LoginPageForm'
import BlocVideo  from '../video/BlocVideo'
import '../factory/factory.css';
import 'bootstrap/dist/css/bootstrap.min.css';



//https://www.robinwieruch.de/local-storage-react


const HomePage = () => {

  //const [count, setCount] = useState(BOX.getSession('sessionUser'));
  //console.log(BOX.URL_SERVEUR + "**" + BOX.getSession('sessionUser')  );
  return (
    <div>
        <HeaderPage/>
      <div className="formatnormal">
        <div className="homephoto">
          <div className="container homephototexte"> 
            <span className="homephotopolice">
                       L'apprentissage des échecs à l'école 
                       {/*Il faut faire entrer les échecs dans les écoles, ils donnent des outils dans la vie : la logique, la résilience, savoir trancher, accepter une défaite, maîtriser l’euphorie d’une victoire, ça rend plus fort.*/}
                       {/*Texte Home  {count}*/}
            </span>
          </div>
        </div>
        <div className="m2"> 
                    {/*    
          <div className="fdr flexjustifycontentspace">
              <div className="fondcolor l4px minl4px"> </div>
              <div className=" m5px bloctexte color w100">

                        <div  className='fdr flexjustifycontentspace '>   
                                <div className="" >Les outils à votre disposition </div>
                                <BlocVideo myClassName="amande pointer mailto"  fontawesome="info" titre="Petite vidéo pour tout comprendre" lien="https://youtu.be/veylzl_Jq3Q" retour="/main"/>  
                                 
                        </div>
              </div>
          </div>
          <br/>
                    */} 
          <HomeModule/>
          <div className="bloctexte color"> 
            Il faut faire entrer les échecs dans les écoles, ils donnent des outils pour la vie : la logique, la résilience, savoir trancher, accepter une défaite, maîtriser l’euphorie d’une victoire, ça rend plus fort.
        </div>

        </div>
      </div>
      <div className="formatmobile ">
          <Form />
      </div>
    </div>
  )
}

export default HomePage;