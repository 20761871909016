
import React from 'react'
//import { Link } from 'react-router-dom'
import HeaderPage from '../factory/HeaderPageSimple'
import HomeModule from '../factory/HomeModule'
import Form       from './LoginPageForm'


export default function LoginPage() {
  return (
    <div className="h100vh fondimage">
        <HeaderPage/>
        <div className="homephoto00">
          <div className="container homephototexte   pull-right">
            <Form />
          </div>
        </div>



    </div>
  )
}
