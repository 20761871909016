import React from 'react'
import {Redirect} from 'react-router-dom';
import InscriptionPageConfirm from './InscriptionPageConfirm'
import Message from '../factory/Message'
//import * as BOXSession from "../factory/BoxSession";
import * as BOXApi from "../factory/BoxApi";
import '../factory/factory.css';
import '../factory/animate.css'; 

export default  class InscriptionPageForm  extends React.Component {
  constructor(props) {
    super(props);
    this.submitHandler   = this.submitHandler.bind(this);
    this.pseudo     = React.createRef();
    this.email      = React.createRef();
    this.pwd        = React.createRef();
    this.profil     = React.createRef();
    this.ecolenom   = React.createRef();
    this.ecolecp    = React.createRef();
    this.ecoleville = React.createRef();
    this.dep        = React.createRef();
    this.classe     = React.createRef();
    this.state = { 
      ecran2        : false,
      pseudo        : '',email:'',pwd:'',profil:'...',ecolenom:'',ecolecp:'',ecoleville:'',dep:'...',classe:'...',
      departements  : [],classes:[],classesUi:[],
      messageTexte  : '',messageTemps:0,messageStyle:"info",
      redirectToHome: false  };
  }

  componentDidMount() {
    fetch(BOXApi.inscriptionClasseUrl(), BOXApi.inscriptionClasseRequest())
    .then(response => response.text())
    .then(result => this.trtSubmitInscriptionClasse(JSON.parse(result)))
    //.then(result => this.setState({departements:JSON.parse(result).departemens}))
    .catch(error => console.log(error));


  }


  myChangeHandler = (event) => {
    if(event.target.name === "pseudo")    { this.setState({pseudo: event.target.value}); }
    if(event.target.name === "email")     { this.setState({email:   event.target.value});   }
    if(event.target.name === "pwd")       { this.setState({pwd:   event.target.value});   }
    if(event.target.name === "profil")    { this.setState({profil:   event.target.value});   }
    if(event.target.name === "ecolenom")  { this.setState({ecolenom:   event.target.value});   }
    if(event.target.name === "ecolecp")   { this.setState({ecolecp:   event.target.value});   }
    if(event.target.name === "ecoleville"){ this.setState({ecoleville:   event.target.value});   }
    if(event.target.name === "dep")       { 
        //this.setState({dep:   event.target.value});
        let classesUi = [];
        for (const classe of this.state.classes) {
          //console.log(JSON.stringify(classe) + '//' + this.state.dep + "//"+event.target.name+"//"+event.target.value )
          if (classe.departement === event.target.value) {classesUi.push(classe);}
        }
        this.setState({dep:   event.target.value,classesUi: classesUi}); 
        //console.log("test3 " + JSON.stringify( this.state.classesUi));
    }
    if(event.target.name === "classe")    { this.setState({classe:   event.target.value});   }
    
  }

  submitHandler = (event) => {
    //this.setState(() => ({ ecran2: true }))
    //return
    event.preventDefault();
    this.setState( {messageTexte: ''})
    console.log('trt submit' + this.state.messageTexte);
    if(this.state.pseudo === '' )  {
      this.setState({ messageTexte: "Ooops, Pseudo obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
      this.pseudo.current.focus();
      return;   
    }
    if(this.state.email === '' )   {
      this.setState({ messageTexte: "Ooops, Email obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
      this.email.current.focus();
      return;
    }
    if(this.state.pwd === '' )     {
      this.setState({ messageTexte: "Ooops, Mot de passe obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } ); 
      this.pwd.current.focus();
      return;
    }
    if(this.state.profil === '...'){
      this.setState({ messageTexte: "Ooops, choisis ton profil !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
      this.profil.current.focus();
      return;
    }
    if(this.state.profil === 'prof'){
      if(this.state.ecolenom === ''){
        this.setState({ messageTexte: "Ooops, nom de l'école obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
        this.ecolenom.current.focus();
        return;
      }   
      if(this.state.ecolecp === ''){
        this.setState({ messageTexte: "Ooops, code postal de l'école obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
        this.ecolecp.current.focus();
        return;
      }   
      if(this.state.ecoleville === ''){
        this.setState({ messageTexte: "Ooops, ville de l'école obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
        this.ecoleville.current.focus();
        return;
      }   
    }
    if(this.state.profil === 'eleve'){
      if(this.state.dep === '...'){
        this.setState({ messageTexte: "Ooops, veuillez sélectionner un département  !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
        this.dep.current.focus();
        return;
      }   
      if(this.state.classe === '...'){
        this.setState({ messageTexte: "Ooops, veuillez sélectionner une classe dans votre école !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
        this.classe.current.focus();
        return;
      }   
    }




    console.log("traitement inscription " + BOXApi.inscriptionUrl());
    fetch(BOXApi.inscriptionUrl(), BOXApi.inscriptionRequest(JSON.stringify(
          {"mode":"NEW","eleve":{"site":"monecoledesechecs",
          "email":this.state.email,
          "login":this.state.pseudo,
          "pwd":this.state.pwd,
          "role":this.state.profil,
          "ecolenom":this.state.ecolenom,
          "ecolecp":this.state.ecolecp,
          "ecoleville":this.state.ecoleville,
          "groupe":this.state.classe,
         }}))
    )
    .then(response => response.text())
    .then(result => this.trtSubmit(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }
  
  errorSubmit = (error) =>{
    if(error  ){
      this.setState(() => ({ messageTexte: "Ooops, erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
  }
  
  trtSubmit = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json.reponse  && json.reponse.codeRetour && json.reponse.codeRetour === "OK"){
      // TRAITEMENT REPONSE SERVEUR OK
      this.setState(() => ({ ecran2: true }))
    }else{
      // TRAITEMENT REPONSE SERVEUR KO
      try {
        this.setState(() => ({ messageTexte: 'Oooops '+json.reponse.errors[0]+' !!!' , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
        try {
          if(json.reponse.errors[0] === 'email invalide' ){this.email.current.focus(); }
          if(json.reponse.errors[0] === ' pseudo existe déjà, veuillez choisir une autre valeur' ){this.pseudo.current.focus(); }
          
        } catch (error) {}
      } catch (error) {
        this.setState(() => ({ messageTexte: "Ooops, erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      }
    }
  }

  trtSubmitInscriptionClasse = (json) =>{
    console.log(">>> trt submit trtSubmitInscriptionClasse " + JSON.stringify(json))
    let ds = [];
    let cs = [];
    if(json.departements !== null ) {ds = json.departements }
    if(json.classes      !== null ) {cs = json.classes }
    this.setState({departements: ds,classes:cs });
    console.log("test " + JSON.stringify(this.state.departements));
  }



  render() {
    if (this.state.redirectToHome) {  return <Redirect to='/main' />   }
    if (this.state.ecran2)         {  return <InscriptionPageConfirm/>   }
    return (
      <div className='text-center'>
        <div className="fondblanc bordure fdc  animated pulse rw50" >
            <h4>Inscrivez-vous et commencez {this.state.profil === 'eleve'?' à apprendre':''}{this.state.profil === 'prof'?' à transmettre':''}</h4>
            <form className="m5" onSubmit={this.submitHandler}>
              <div className="text-left">{this.state.pseudo === '' ? <br/> : 'Pseudo'}</div>
              <div className="">         <input className="w100" type="text" name="pseudo"     ref={this.pseudo}     onChange={this.myChangeHandler} placeholder="Pseudo"/></div>
              <div className="text-left">{this.state.email === '' ? <br/> : 'Email'}</div>
              <div className="">         <input className="w100" type="text" name="email"      ref={this.email}      onChange={this.myChangeHandler} placeholder="Email"/></div>
              <div className="text-left">{this.state.pwd === '' ? <br/> : 'Mot de passe'}</div>
              <div className="">         <input className="w100" type="password" name="pwd"        ref={this.pwd}        onChange={this.myChangeHandler} placeholder="Mot de passe"/></div>
              <br/>
              <div className="text-left">Profil</div>
              <select className="w100 myform" name="profil"  value={this.state.profil} ref={this.profil} onChange={this.myChangeHandler}>
              <option value="...">Choisir une valeur</option>
                <option value="eleve">Elève</option>
                <option value="prof">Prof</option>
              </select>
              <br/><br className="formatnormal"/>


              <div className={this.state.profil === 'eleve' ?'':'nodisplay' }>
                <div className="text-left">Département de ton école</div>
                <select className="w100 myform" name="dep" ref={this.dep}  value={this.state.dep} onChange={this.myChangeHandler}>
                    <option value="...">Choisir une valeur</option>
                    {this.state.departements.constructor === Array ? this.state.departements.map((departement) => <option value={departement.key}> {departement.value}</option>):'' }    
                  </select>
                <div className="text-left">Nom de ton école, sélectionne ta classe</div>
                <select className="w100 myform" name="classe"  ref={this.classe}   value={this.state.classe} onChange={this.myChangeHandler}>
                    <option value="...">Choisir une valeur</option>
                    {this.state.classesUi.map === undefined ? '' : this.state.classesUi.map((classe) => <option value={classe.idGroupe}> {classe.nom}</option>) }    
                </select>
              </div>

              <div className={this.state.profil === 'prof' ?'':'nodisplay' }>
                <div className="text-left">{this.state.ecolenom === '' ? <br/> : "Nom de l'école"}</div>
                <div className="">         <input className="w100" type="text" name="ecolenom"   ref={this.ecolenom} onChange={this.myChangeHandler} placeholder="Nom de l'école" /></div>
                <div className="text-left">{this.state.ecolecp === '' ? <br/> : "Code postal de l 'ecole"}</div>
                <div className="">         <input className="w100" type="text" name="ecolecp"    ref={this.ecolecp} onChange={this.myChangeHandler} placeholder="Code postal de l'école" /></div>
                <div className="text-left">{this.state.ecoleville === '' ? <br/> : "Ville de l'école"}</div>
                <div className="">         <input className="w100" type="text" name="ecoleville" ref={this.ecoleville} onChange={this.myChangeHandler} placeholder="Ville de l'école"/></div>
              </div>


              <br/>
              <div className="m2 text-center">
                <span className={'text-center rond m2'}  onClick={this.submitHandler}> S'inscrire <i class="fas fa-user-plus"></i> </span>
              </div>        
            </form>

            <div className="text-center">
                <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            </div>
            <br/>
        </div>
      </div>
    )
  }
}
