import React from 'react'
import { Link } from 'react-router-dom'
import {Redirect} from 'react-router-dom';
import '../factory/factory.css';
import './MainPage.css';


export default class BlocHome extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleShowVideo = this.handleShowVideo.bind(this);
      this.state           = {redirectToVideo: false  };
    }
    handleShowVideo() {
        console.log("test");
        sessionStorage.setItem('sessionVideoLien',   this.props.lien);
        sessionStorage.setItem('sessionVideoRetour', this.props.retour);
        sessionStorage.setItem('sessionVideoTitre',  this.props.titreyoutube);
        this.setState(() => ({ redirectToVideo: true }))
    }

    render() {
    if (this.state.redirectToVideo) {  return <Redirect to='/video' />   }
    return (
        <div className="blocHome00 color fondblanc" onClick={this.handleShowVideo}>
            <div className="size120"> {this.props.titre} </div>
            <div className="bloctexte"> {this.props.texte} </div>
            <div className="text-right">
                {/*<Link to="" onClick={this.handleShowVideo} > > Voir plus  </Link>*/}
                <Link  to="" onClick={this.handleShowVideo} > <font className="amande"> En savoir plus</font>  <i class="fa fa-chevron-circle-right rondplus"></i>  </Link>
            </div>
        </div>
    )
  }
}  