import React from 'react'
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom'
import LoginPageInactif from "./LoginPageInactif"
import Message from '../factory/Message'
import * as BOX from "../factory/Box";
import * as BOXSession from "../factory/BoxSession";
import * as BOXApi from "../factory/BoxApi";
import '../factory/factory.css';
import '../factory/animate.css';
import HomeMobile from '../factory/HomeMobile'

export default  class LoginPageForm  extends React.Component {
  constructor(props) {
    super(props);
    this.debuginitprof = this.debuginitprof.bind(this);
    this.state = { 
      login         : '',pwd:'',
      messageTexte  : '',messageTemps:0, messageStyle:"info",
      userlogin     : '',usermail:'',userdatecrt:'',
      redirectToHome: false,
      redirectToCompteInactif:false,
      showBandeauMessage : true,
      };
  }

  debuginitprof = () => {
    this.setState({login: 'ecole',pwd:   'ecole'}); 
   
  }



  changeHandler = (event) => {
    //debugger;
    if(event.target.name === "login"){ 
      this.setState({login: event.target.value,showBandeauMessage: event.target.value ===''?true: false}); 
    }
    if(event.target.name === "pwd")  { 
      this.setState({pwd:   event.target.value});   
    }

  }

  submitHandler = (event) => {
    console.log('trt submit');
    event.preventDefault();


    this.setState(() => ({ messageTexte: ""}))
    if(this.state.login === '' && this.state.pwd === ''){
      this.setState(() => ({ messageTexte: "Ooops, Login et mot de passse obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    let body = JSON.stringify({"login":this.state.login,"password":this.state.pwd});
    fetch(BOXApi.loginUrl(), BOXApi.loginRequest(body))
      .then(response => response.text())
      .then(result => this.trtReponseServer(JSON.parse(result)))
      .catch(error => this.trtReponseServerKO(error));
  }
  
  trtReponseServerKO = (error) =>{
    if(error  ){this.setState(() => ({ messageTexte: "Ooops, erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } ));  }
  }
  
  trtReponseServer = (json) =>{
    console.log(">>> trt trtReponseServer " +  JSON.stringify(json))
    if(json.user  && json.user.profil && (json.user.profil === "INACTIF" || json.user.statut === null )){
      this.setState(() => ({redirectToCompteInactif:true,userlogin:json.user.login,usermail:json.user.email,userdatecrt:json.user.dateCreation}))
    }  

    if(json.reponse  && json.reponse.codeRetour && json.reponse.codeRetour === "OK"&& json.user.statut === "OK"){
      //debugger;
      if(json.user.profil === "ELEVE" ){
        //window.location.replace('http://127.0.0.1:81/chessV2.html?reverse&'+json.user.id+'&'+json.token+'&zen')
        window.location.replace(BOX.URL_CLIENT+'/chess.html?reverse&'+json.user.id+'&'+json.token+'&zen')
      }else{
        BOXSession.openSession(this.state.login,json);
        this.setState(() => ({ redirectToHome: true }))
      }

      return;
    }
    this.setState(() => ({ messageTexte: "Ooops, login invalide !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    
  }


  render() {
    if (this.state.redirectToHome) {  return <Redirect to='/main' />   }
    if (this.state.redirectToCompteInactif ) {  return <LoginPageInactif login={this.state.userlogin} mail={this.state.usermail} date={this.state.userdatecrt}/>  }
    return (
      <div>

         <div className="formatmobile ">
            {this.state.showBandeauMessage ? <HomeMobile /> : ''}
         </div>

        
          <div className="fondblanc bordure fdc w50x animated pulse" >
            <div className="m2">

              <h3 className="formatnormal">Connectez-vous à votre compte: </h3>
              <h3 className="formatmobile">Connectez-vous à votre compte: </h3>
              <form onSubmit={this.submitHandler}>
                <div className="m2">{this.state.login === '' ? <br/> : 'Login'}</div>
                <div className="m2">
                  <input className="w100" type="text" name="login"  placeholder="Login"  onChange={this.changeHandler} />
                </div>
                <div className="m2">{this.state.pwd === '' ? <br/> : 'Mot de passe'}</div>
                <div className="m2">
                  <input className="w100" type="password" name="pwd" placeholder="Mot de passe" onChange={this.changeHandler} />
                </div>
                <br/>              
                <div className="m5 text-center">
                  <span className={'text-center rond m2'}  onClick={this.submitHandler}><i class="fas fa-sign-in-alt"></i>  Se connecter  </span>
                </div>        
              </form>

              <div className="text-right">
                  <Link  to="/resetPassword">Mot de passe oublié ?</Link>&nbsp;
              </div>
              <div className="text-right">
                  <Link  to="/inscription">Création d'un compte</Link>&nbsp;
              </div>
                {/* 
              <span  className="blocOrange m2"  onClick={this.debuginitprof}> **  debug compte prof ** </span>
                */}
              <br/>
              <div className="text-center">
                  <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
              </div>
            </div>
        </div>
      </div>
    )
  }
}
