
export const NOM_SITE   = "monEcoleDesEchecs.fr";
//export const URL_SERVEUR= "http://127.0.0.1:8080";
//export const URL_CLIENT = "http://127.0.0.1:81";
export const URL_SERVEUR= "https://www.monecoledesechecs.fr/elearnchess";
export const URL_CLIENT = "https://app.monecoledesechecs.fr";
export const VIDEOS     = [
    {"titre":"Leçon n°1 - Introduction aux échecs", "lien":"https://youtu.be/lZX9CvNLtgI","retour":"/main"},
    {"titre":"Leçon n°2 - Le pion",                 "lien":"https://youtu.be/oA53afn56Do","retour":"/main"},
    {"titre":"Leçon n°3 - La TOUR et le FOU",       "lien":"https://youtu.be/QRU8UDl35vs","retour":"/main"},
    {"titre":"Leçon n°4 - La DAME",                 "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°5 - Le CAVALIER",             "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°6 - Le ROI",                  "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°8 - Mat de l'escalier",       "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°9 - La valeur des pièces",    "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°10 - Le Roque",               "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°11 - Echec et Mat EXPRESS",   "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°12 - Bien démarrer la partie","lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°13 - La prise en passant",    "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°14 - Attention au PAT",       "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"},
    {"titre":"Leçon n°15 - Le CAVALIER",            "lien":"https://www.youtube.com/watch?v=oA53afn56Do&list=PLye-2Z_VC2fcpf3YSqL4jhY_8BpSOB8rY&index=2","retour":"/main"}
];




/*export function  getSession(param){ 
    console.log("sessionStorage.getItem("+param+") " + sessionStorage.getItem(param) );
    return sessionStorage.getItem(param)
};*/



