// https://react-bootstrap-v3.netlify.app/
// https://fr.reactjs.org/docs/hooks-reference.html#usecontext
import React from 'react'
import HeaderPage from '../factory/HeaderPageConnect'
import HeaderPageRetour from '../factory/HeaderPageConnectRetour'
import HeaderPageSimple from '../factory/HeaderPageSimple'
import BlocVideo  from '../video/BlocVideo'
import * as BOX from "../factory/Box";
import * as BOXApi from "../factory/BoxApi";
import * as BOXSession from "../factory/BoxSession";
//import * as BOX from "../factory/Box";
import '../factory/factory.css';
import './MainPage.css';
//import '../factory/popup.css'
import AddClasse  from '../prof/AddClasse';
import AddExo     from '../prof/AddExo';
import RemoveExo     from '../prof/RemoveExo';
import AddPartie  from '../prof/AddPartie';
import DeleteExo  from '../prof/DeleteExo';
import EditEcole from '../prof/EditEcole';
import EditClasse from '../prof/EditClasse';
import EditEleve  from '../prof/EditEleve';
import Popup from '../factory/Popup';

export default class HomePage extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.handleShowBox    = this.handleShowBox.bind(this);
      this.handleShowRetour = this.handleShowRetour.bind(this);
      this.menuProf         = this.menuProf.bind(this);
      this.addRecord        = this.addRecord.bind(this);
      this.removeRecord     = this.removeRecord.bind(this);
      this.editRecord       = this.editRecord.bind(this);
      this.apiGetEleves     = this.apiGetEleves.bind(this);
      this.apiGetExos       = this.apiGetExos.bind(this);
      this.apiGetParties    = this.apiGetParties.bind(this);
      this.refreshGroupes   = this.refreshGroupes.bind(this);
      this.refreshExos      = this.refreshExos.bind(this);
      this.refreshParties   = this.refreshParties.bind(this);
      this.noDisplayEditRcd = this.noDisplayEditRcd.bind(this);
      this.sortNombreExo    = this.sortNombreExo.bind(this);
      this.sortNombrePoint  = this.sortNombrePoint.bind(this);
      this.togglePopup      = this.togglePopup.bind(this);
      this.divProf          = React.createRef();
      this.state            = {redirectToVideo: false,
                              numBox           :0,  
                              col4             :"col-sm-4",
                              col8             :"col-sm-8",
                              displayrow1      :"",displayrow2:"",displayrow3:"",displaycol1:"",displaycol2:"",
                              displaytitre     :"",
                              displayretour    :"nodisplay",
                              displaymenu      :"",
                              displayprofedit  :"nodisplay", 
                              displayclasseadd :"nodisplay",
                              displayclasseedit:"nodisplay",
                              displayeleveedit :"nodisplay",
                              displayexoadd    :"nodisplay",
                              displayexoremove :"nodisplay",
                              displayexoedit   :"nodisplay",
                              displayexodel    :"nodisplay",
                              displaypartie    :"nodisplay",
                              displaypartieadd :"nodisplay",
                              groupes          : [],  
                              eleves           : [],
                              eleve            : {},
                              exos             : [],
                              parties          : [],
                              exo              : {},
                              partie           : {}, 
                              model            : '',
                              id               : '',
                              nom              : '',
                              iframepartie     : 'http://127.0.0.1:81/chessV2/static/partie.html?idPartie=4600812098281285507&maCouleur=b&leTrait=n&h=100px&lesCoups=d2d4&nbCoupsJoue=1&server=http://127.0.0.1:8080&idUser=1&statut=En%20cours&avant=22h&mode=consultation&d=1609797702073&taille=param&largueur=383&hauteur=492',
                              iframeexo        : 'http://127.0.0.1:81/chessV2/static/displayExo.html?idExo=3993803915064770682&size=270&server=http://127.0.0.1:8080',
                              divprofwidth     : 0,
                              divprofheight    : 0,
                              filtreClasse     : '...',
                              triNbExoDesc     : true,
                              triNbPointDesc   : true,
                              nbEleve          : 0, 
                              isOpen           : 0,
                            };
    }
 
    togglePopup(x) {
      this.setState(() => ({isOpen: x}))    
    }

    componentDidMount() {
        this.refreshGroupes();
        this.refreshExos();
        this.apiGetEleves();
        this.apiGetParties();
        const width = this.divProf.current.clientWidth;
        const height =  (0.75 * window.innerHeight);
        this.setState(() => ({divprofwidth: ~~width, divprofheight: ~~height}))    
        // gestion pour retour de video youtube
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")
        console.log("VALEUR ID " +  id) // 159
        if(id && id === "video" ){
            let value = 1;
            this.noDisplayEditRcd()
            this.setState(() => ({displaymenu:"",displaytitre:"nodisplay",displayretour:"",numBox:value,displayprofedit:'nodisplay',
              displayrow1:"",displayrow2:"nodisplay",displayrow3:"nodisplay",displaycol1:"",displaycol2:"nodisplay",
              col4:"col-sm-1 invisible", col8: "col-sm-11" }))
        }
     }

    videos =[
        {"titre":"Leçon n°1 - Introduction aux échecs","lien":"https://youtu.be/lZX9CvNLtgI","retour":"/main?id=video"},
        {"titre":"Leçon n°2 - Le pion",                "lien":"https://youtu.be/oA53afn56Do","retour":"/main?id=video"},
        {"titre":"Leçon n°3 - La TOUR et le FOU",      "lien":"https://youtu.be/QRU8UDl35vs","retour":"/main?id=video"},
        {"titre":"Leçon n°4 - La DAME",                "lien":"https://www.youtube.com/watch?v=Lqmt9QSyE-Q","retour":"/main?id=video"},
        {"titre":"Leçon n°5 - Le CAVALIER",             "lien":"https://www.youtube.com/watch?v=17rGPlXeneg","retour":"/main?id=video"},
        {"titre":"Leçon n°6 - Le ROI",                  "lien":"https://www.youtube.com/watch?v=tEPBikrxutc","retour":"/main?id=video"},
        {"titre":"Leçon n°7 - Échec au ROI / Échec et MAT", "lien":"https://www.youtube.com/watch?v=SnLudTbcJDs","retour":"/main?id=video"},
        {"titre":"Leçon n°8 - Mat de l'escalier",                              "lien":"https://www.youtube.com/watch?v=AtnAJyygLmQ","retour":"/main?id=video"},
        {"titre":"Leçon n°9 - La valeur des pièces",                           "lien":"https://www.youtube.com/watch?v=pIyfq1gjdVw","retour":"/main?id=video"},
        {"titre":"Leçon n°10 - Le Roque",                                      "lien":"https://www.youtube.com/watch?v=-iWyoRMdxIA","retour":"/main?id=video"},
        {"titre":"Leçon n°11 - Echec et Mat EXPRESS",                          "lien":"https://www.youtube.com/watch?v=JCEGEFxAUR4","retour":"/main?id=video"},
        {"titre":"Leçon n°12 - Bien démarrer la partie",                       "lien":"https://www.youtube.com/watch?v=_aDM2fP2JdA","retour":"/main?id=video"},
        {"titre":"Leçon n°13 - La prise en passant",                           "lien":"https://www.youtube.com/watch?v=Obukb9uiWik","retour":"/main?id=video"},
        {"titre":"Leçon n°14 - Attention au PAT",                              "lien":"https://www.youtube.com/watch?v=8MOXc4p8quc","retour":"/main?id=video"},
        {"titre":"Apprendre les échecs à l'école: le matériel et le placement","lien":"https://youtu.be/mg_TiHaEWH4","retour":"/main?id=video"},
        {"titre":"Apprendre les échecs à l'école: la tour",                    "lien":"https://youtu.be/kdEOZILxOnw","retour":"/main?id=video"},
        {"titre":"Apprendre les échecs à l'école: le fou",                     "lien":"https://youtu.be/XhItWv-TPg0","retour":"/main?id=video"},
        {"titre":"Apprendre les échecs à l'école: la dame",                    "lien":"https://youtu.be/Ys1oUhbrXng","retour":"/main?id=video"}

    ];
    renderVideoAll  = this.videos.map((video) => <li className="text-left">             <BlocVideo titre={video.titre} lien={video.lien} retour={video.retour}  /> </li>);
    renderVideo     = this.videos.map((video) => <li className="nodisplaya5 text-left"> <BlocVideo titre={video.titre} lien={video.lien} retour={video.retour}  /> </li>);

    formatDate(date){
        return new Date(date).toLocaleDateString() 
    }
   
    refreshGroupes() {
        //debugger;
        var test = BOXSession.getJsonParse('sessionGroupes');
        console.log("Attention  groupes ==  null" + test);
        if( BOXSession.getJsonParse('sessionGroupes')  === null){   
            console.log("Attention  groupes ==  null");
            this.setState(() => ({ groupes: []}));
        } else {
            this.setState(() => ({ groupes: BOXSession.getJsonParse('sessionGroupes')}));
        }
    }

    refreshExos() {
        //debugger
        var test = BOXSession.getJsonParse('sessionExos');
        console.log("Attention  groupes ==  null" + test);

        if(test.constructor === Array ){
            this.setState(() => ({ exos: BOXSession.getJsonParse('sessionExos')}));
        }else{
            console.log("Attention  exos ==  null");
            this.setState(() => ({ exos: []}));
            
        }
    }
    refreshParties() {
        //debugger
        if( BOXSession.getJsonParse('sessionParties')  === null){   
            console.log("Attention  parties ==  null");
            this.setState(() => ({ parties: []}));
        } else {
            this.setState(() => ({ parties: BOXSession.getJsonParse('sessionParties')}));
        }
    }

    myChangeHandler = (event) => {
        if(event.target.name === "filtreClasse"){ this.setState({filtreClasse: event.target.value}); }
      }

    apiGetEleves(){
        console.log("chargement des eleves " + BOXApi.profGetUserUrl());
        fetch(BOXApi.profGetUserUrl(), BOXApi.profGetUserRequest())
        .then(response => response.text())
        .then(result => this.setState(() => ({eleves:JSON.parse(result).eleves})))
        .catch(error => console.log('error', error));
    }    

    apiGetExos(){
        //debugger;
        console.log("chargement des exos " + BOXApi.profGetExoUrl());
        fetch(BOXApi.profGetExoUrl(), BOXApi.profGetExoRequest())
        .then(response => response.text())
        //.then(result =>  sessionStorage.setItem('sessionExos', JSON.stringify(result.exercices) ) )
        //.then(result =>  this.apiGetExos(result) )
        .then(result => this.trtSubmitGetExo(JSON.parse(result)))
        .catch(error => console.log('error', error));
    } 

    trtSubmitGetExo = (json) =>{
         debugger;
        sessionStorage.setItem('sessionExos', JSON.stringify(json.exercices) )
        this.setState(() => ({ exos: BOXSession.getJsonParse('sessionExos')}));
    }

    apiGetParties(){
        console.log("chargement des parties " + BOXApi.profGetPartieUrl());
        fetch(BOXApi.profGetPartieUrl(), BOXApi.profGetPartieRequest())
        .then(response => response.text())
        .then(result => this.setState(() => ({parties:JSON.parse(result).parties})))
        .catch(error => console.log('error', error));
    } 

    handleShowRetour(){
        this.setState(() => ({numBox:0, displaytitre:"",col4:"col-sm-4",col8:"col-sm-8", displayretour:"nodisplay",displayrow1:"",displayrow2:"",displayrow3:"", displaycol1:"", displaycol2:""}))
        //this.menuProf();
    }

    noDisplayEditRcd(){
        this.setState(() => ({displaymenu:      "nodisplay", 
                              displayprofedit:  "nodisplay",
                              displayclasseadd: "nodisplay", 
                              displayclasseedit:"nodisplay",
                              displayeleveedit: "nodisplay",
                              displayexoadd:    "nodisplay",
                              displayexoremove: "nodisplay",
                              displayexoedit:   "nodisplay",
                              displayexodel:    "nodisplay",
                              displaypartie:    "nodisplay",
                              displaypartieadd: "nodisplay",
                            }))    
    }

    menuProf(){
        this.noDisplayEditRcd()
        this.setState(() => ({displaymenu: "",numBox:0,col4:"col-sm-4",col8:"col-sm-8", }))
        this.handleShowRetour();
    }

    handleShowBox(event) {
        let value = event.target.id;
        this.noDisplayEditRcd()
        this.setState(() => ({displaymenu:"",displaytitre:"nodisplay",displayretour:"",numBox:value}))
        if(value === 0){
            this.setState(() => ({displayrow1:"",displayrow2:"",displayrow3:"",displaycol1:"",displaycol2:""}))
            return
        }
        this.setState(() => ({displaymenu:"",displayprofedit:'nodisplay',  displayrow1:"nodisplay",displayrow2:"nodisplay",displayrow3:"nodisplay",displaycol1:"nodisplay",displaycol2:"nodisplay"}))
        if(value == 1 || value == 2 ){ this.setState(() => ({displaymenu:"", displayrow1: "",col4:"col-sm-1 invisible", col8: "col-sm-11" }))}
        if(value == 3 || value == 4 ){ this.setState(() => ({                displayrow2: "",col4:"col-sm-1 invisible", col8: "col-sm-11" }))}
        if(value == 5 || value == 6 ){ this.setState(() => ({                displayrow3: "",col4:"col-sm-1 invisible", col8: "col-sm-11" }))}
        if(value == 1 || value == 3 || value == 5){ this.setState(() => ({ displaycol1: "" }))}
        if(value == 2 || value == 4 || value == 6){ this.setState(() => ({ displaycol2: "" }))}
        if(value == 4 ){ 
            console.log("Appel display exo");
            this.apiGetExos();
            //this.refreshExos();
        }
    }

    sortNombreExo(){
        let eleves = this.state.eleves;
        if(this.state.triNbExoDesc){
            eleves.sort((a, b) => {
                return a.nbExercice - b.nbExercice;
            });
         }else{
            eleves.sort((a, b) => {
                return b.nbExercice - a.nbExercice;
            });
         }
        this.setState(() => ({ eleves: eleves,  triNbExoDesc: !this.state.triNbExoDesc }))
    }
    sortNombrePoint(){
        let eleves = this.state.eleves;
        if(this.state.triNbPointDesc){
            eleves.sort((a, b) => {
                return a.nbExercice - b.nbPoint;
            });
         }else{
            eleves.sort((a, b) => {
                return b.nbExercice - a.nbPoint;
            });
         }
        this.setState(() => ({ eleves: eleves,  triNbPointDesc: !this.state.triNbPointDesc }))
    }
    addRecord(event) {
        let value = event.target.id
        this.noDisplayEditRcd()
        if(value === "groupe"){this.setState(() => ({col4:"col-sm-4",col8:"col-sm-8",displayclasseadd:""}))}
        if(value === "exo")   {this.setState(() => ({col4:"col-sm-4",col8:"col-sm-8",displayexoadd:""}))}
        if(value === "partie"){this.setState(() => ({col4:"col-sm-4",col8:"col-sm-8",displaypartieadd:""}))}
    }   
    removeRecord(event) {
        let value = event.target.id
        this.noDisplayEditRcd()
        if(value === "removeexo")   {this.setState(() => ({col4:"col-sm-4",col8:"col-sm-8",displayexoremove:""}))}
    }  
    editRecord(event) {
        let value = event.target.id;
        let model = value.split('|')[0]
        let id    = value.split('|')[1]
        let mode  = value.split('|')[2]
        //debugger;
        this.noDisplayEditRcd()
        if(model === 'prof' ){
            this.setState(() => ({displayprofedit:'',   model: model,id:id}))    
        }
        if(model === 'groupe' ){
            let nbEleve = 0;
            this.state.eleves.forEach(element => (element.groupe === id? nbEleve ++:''));
            this.setState(() => ({col4:"col-sm-4",col8:"col-sm-8",displayclasseedit:"", model: model,id:id,nbEleve:nbEleve}))    
            this.state.groupes.forEach(element => (element.id == id?this.setState(() => ({ nom:element.nom  })):''));
            console.log('nbEleve ' + nbEleve)
        }
        if(model === 'eleve' ){
            debugger;
            this.setState(() => ({col4:"col-sm-6",col8:"col-sm-6",displayeleveedit:"",model: model,id:id}))    
            this.state.eleves.forEach(element => (element.idEleve === id?this.setState(() => ({ eleve:element  })):''));
            this.state.eleves.forEach(element => (element.idEleve === id?console.log(element) :''));
            //document.getElementById("popupSendMail").classList.add("is-visible");
        }
        if(model === 'partie' ){
            //iframepartie     : 'http://127.0.0.1:81/chessV2/static/partie.html?idPartie=4600812098281285507&maCouleur=b&leTrait=n&h=100px&lesCoups=d2d4&nbCoupsJoue=1&server=http://127.0.0.1:8080&idUser=1&statut=En%20cours&avant=22h&mode=consultation&d=1609797702073&taille=param&largueur=383&hauteur=492',
            debugger;
            let partie = {};
            this.state.parties.forEach(element => (element.id == id?partie=element:''));
            let lesCoups = "";
            if(partie && partie.lesCoups && partie.lesCoups !== ''){lesCoups = partie.lesCoups }
            let iframepartie = BOX.URL_CLIENT+'/chessV2/static/partie.html?idPartie='+id+'&maCouleur=b&leTrait=n&h=100px&lesCoups='+lesCoups+'&nbCoupsJoue=1&server='+BOX.URL_SERVEUR+'&idUser=1&statut=En%20cours&avant=22h&mode=consultation&d=1609797702073&taille=param&largueur=383&hauteur=492'

            this.setState(() => ({col4:"col-sm-6",col8:"col-sm-6",displaytitre:"nodisplay",displayretour:"", numBox:5, displayrow1:"nodisplay",displayrow2:"nodisplay",displayrow3:"",displaycol1:"",displaycol2:"nodisplay"}))
            this.setState(() => ({displaypartie:"",  model: model,id:id,partie:partie,iframepartie:iframepartie}))    
            //this.state.groupes.forEach(element => (element.id == id?this.setState(() => ({ nom:element.nom  })):''));
        }
        //debugger
        if(model === 'exo' && mode === undefined  ){
            //let iframeexo  =  'http://127.0.0.1:81/chessV2/static/displayExo.html?idExo=3993803915064770682&size=270&server=http://127.0.0.1:8080'
            debugger
            let exo = {};
            this.state.exos.forEach(element => (element.id == id?   exo=element  :''));
            let pageHtml = "displayExo.html";
            if(exo.type === "Déplacer les pièces"){pageHtml = "displayExoMOVE.html";}
            if(exo.type === "QCM"){pageHtml = "displayExoQCM.html";}

            let iframeexo  =  BOX.URL_CLIENT+'/chessV2/static/'+pageHtml+'?idExo='+id+'&server='+BOX.URL_SERVEUR
            this.setState(() => ({col4:"col-sm-6",col8:"col-sm-6",displaytitre:"nodisplay",displayretour:"", numBox:4, displayrow1:"nodisplay",displayrow2:"",displayrow3:"nodisplay",displaycol1:"nodisplay",displaycol2:""}))
            this.setState(() => ({displayexoedit:"",  model: model,id:id,iframeexo:iframeexo,exo:exo}))    
            //this.setState(() => ({displayexoedit:"",  model: model,id:id,iframeexo:iframeexo}))    
            //this.state.exos.forEach(element => (element.id == id?this.setState(() => ({ exo:element  })):''));
        }
        if(model === 'exo' && mode === "delete"  ){
            this.state.exos.forEach(element => (element.idGroupeExercice == id?this.setState(() => ({ exo:element  })):''));
            this.setState(() => ({col4:"col-sm-4",col8:"col-sm-8",displayexodel:"",  model: model,id:id}))    
        }
    } 

    render() {
        return (
            <div className="h100vh maxh100vh xxnoscroll fondimage">
                <div className="formatnormal">
                        {this.state.numBox === 0 
                                     ? <HeaderPage/>
                                     : <HeaderPageRetour/> 
                                     }
                 </div>
                <div className="formatmobile"><HeaderPageSimple/> </div>
                <div className="mainbloc" >
                <div className="m1">
                    <div className="row h100pc ">
                        <div className={this.state.col4}  >
                            <div className="bordure bordermobile m2 fondblanc maxh88vh text-center">
                                <div className="text-right nodisplay" >  {this.state.numBox} </div>    
    {/* Menu PROF */}
                                <div className={this.state.displaymenu} ref={this.divProf} >
                                    {/*<div className='maintitre' > Mon école {/*this.state.divprofwidth} / {this.state.divprofheight   </div>*/}

                                    <div  className='souligne fdr flexjustifycontentspace '>   
                                        <div className="maintitre"><i class="fas fa-chess-queen"></i> Mon école </div>
                                        <div className='astuce'> 
                                            <BlocVideo myClassName="violet"  fontawesome="info" titre="Comment ça marche" lien="https://youtu.be/veylzl_Jq3Q" retour="/main"/>    
                                        </div>
                                    </div>

                                    <br/> 
                                    <div className="m5 text-center">
                                        <table width="100%">
                                            <thead><td></td><td></td></thead>
                                            <tr><td>Login</td><td align="left">{BOXSession.getJsonParse('sessionUser').login}</td></tr>
                                            <tr><td>Email</td><td align="left">{BOXSession.getJsonParse('sessionUser').email}</td></tr>
                                            <tr><td>Ecole</td><td align="left">{BOXSession.getJsonParse('sessionUser').ecolenom} </td></tr>
                                            <tr><td></td><td align="left">{BOXSession.getJsonParse('sessionUser').ecolecp}   {BOXSession.getJsonParse('sessionUser').ecoleville} </td></tr>
                                        </table>
                                        <div className="m5 text-left fdr">
                                                Présent sur écran d'inscription&nbsp;&nbsp;&nbsp;
                                                {BOXSession.getJsonParse('sessionUser').statutSelect === 'Y'?'Oui':'Non'}&nbsp;&nbsp;&nbsp;
                                                {/*<BlocVideo myClassName="amande"  fontawesome="info" titre="Plus d'infos" lien="https://youtu.be/oA53afn56Do" retour="/main"/>*/} 


                                        <div className="m2px fas fa-lightbulb amande pointer" onClick={() => this.togglePopup(1)}>Plus d'infos</div>
                                        <div>
                                            {this.state.isOpen === 1 && <Popup
                                            content={<>
                                                <b>Comprendre la valeur "présent sur écran d'inscription" </b>
                                                <p>
                                                    <ul>
                                                        <li> Si Oui, les éléves peuvent retrouver l'école sur le formulaire d'inscription. </li>
                                                        <li> Si Non, l'école n'est pas sélectionnable sur le formulaire d'inscription.  </li>
                                                    </ul>
                                                    Conseil: <br></br>Pendant la période d'inscription des élèves, garder la valeur à "Oui". Quand tous les élèves sont inscrits passer la valeur à "Non".

                                                </p>
                                                <button className="rond" onClick={() => this.togglePopup(0)}>Fermer</button>
                                            </>}
                                            handleClose={() => this.togglePopup(0)}
                                            />}
                                        </div>  
                                        </div>
                                        <br/><br/>    
                                        <span className={'text-center rond m2'}  id='prof|000'  onClick={this.editRecord}> <i class="fas fa-user-edit"></i> Modifier</span>
                                    </div>   
                                    

                                     {this.state.numBox === 0 
                                     ? <span><br/><br/><br/></span>
                                     : <span> <hr/>  <span className={'fas fa-arrow-alt-circle-left fa-lg rond'}   onClick={this.menuProf}> retour menu principal </span> <br/><br/>  </span>
                                     } 
 

                                    {/* 

                                    <div class="w100 text-left">
                                    TODO:
                                    <br/>** ELEVE: (Ajouter un bouton pour activier le profil)
                                    <br/>** ELEVE: (Ajouter un filtre par classe)
                                     <br/>** PARTIE: Gestion des pofs pour les blancs et les noirs (modif db obligatoire)
                                    <br/>** DAns eLearnchess la déconnexion dans ramener vers monecolde des echecs
                                    
                                    </div>
                                     */}

                                </div>
{/* Menu PROF / edit prof */}                                                                
                                <div className={'fdc h100pc ' +  this.state.displayprofedit}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i> Mise à jour</div>
                                    <EditEcole login={BOXSession.getJsonParse('sessionUser').login}
                                               email={BOXSession.getJsonParse('sessionUser').email}
                                               ecolenom={BOXSession.getJsonParse('sessionUser').ecolenom}
                                               ecolecp={BOXSession.getJsonParse('sessionUser').ecolecp} 
                                               ecoleville={BOXSession.getJsonParse('sessionUser').ecoleville}
                                               statutSelect={BOXSession.getJsonParse('sessionUser').statutSelect}
                                    />
                                   <hr/> 
                                    <span className={'fas fa-arrow-alt-circle-left fa-lg rond'}   onClick={this.menuProf}> retour menu principal </span>
                                </div>
{/* Menu PROF / add classe */}                                
                                <div className={'fdc h100pc ' +this.state.displayclasseadd}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i> Ajouter </div>
                                    <AddClasse  parentMethod={this.refreshGroupes} id={this.state.id}/>
                                    
                                </div>
{/* Menu PROF / add Exo */}                                                                
                                <div className={'fdc h100pc ' +this.state.displayexoadd}>
                                    <AddExo  parentMethod={this.apiGetExos} groupes={this.state.groupes}/>
                                </div>
{/* Menu PROF / remove Exo */}                                                                
                                <div className={'fdc h100pc ' +this.state.displayexoremove}>
                                    <RemoveExo  parentMethod={this.apiGetExos} groupes={this.state.groupes}/>
                                </div>

{/* Menu PROF / delete Exo (confirm) */}                                                                
                                <div className={'fdc h100pc ' +this.state.displayexodel}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i>Confirmation</div>
                                    <DeleteExo  parentMethod={this.apiGetExos} exo={this.state.exo}/>
                                    <span className={'fas fa-arrow-alt-circle-left fa-lg rond'}   onClick={this.menuProf}> Annuler </span>
                                </div>

{/* Menu PROF / edit classe */}
                                <div className={'fdc h100pc ' +this.state.displayclasseedit}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i> Mise à jour</div>
                                    <EditClasse  parentMethod={this.refreshGroupes} id={this.state.id} nom={this.state.nom}/>
                                    <hr></hr>

                                    <div className={this.state.nbEleve === 0?'nodisplay':'' }>
                                        <div class="w100 text-left h50pc m5px">
                                            Liste des élèves de la classe<hr/>
                                            <table width="100%">
                                            <tr><td>Login</td>
                                                <td  onClick={this.sortNombreExo} > 
                                                    Nombre exercice &nbsp;
                                                    { this.state.triNbExoDesc  ? <i class="fa fa-chevron-circle-down" aria-hidden="true"></i> : <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>}
                                                </td>
                                                <td onClick={this.sortNombrePoint} > 
                                                    Nombre point &nbsp;
                                                    { this.state.triNbPointDesc  ? <i class="fa fa-chevron-circle-down" aria-hidden="true"></i> : <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>}
                                                </td>
                                            </tr>    
                                            {this.state.eleves.map((eleve) => <tr  className={this.state.nom !== eleve.groupeLibelle  ? "nodisplay": ""}><td>{eleve.login}</td><td>{eleve.nbExercice}</td><td>{eleve.nbPoint}</td>  </tr>)}
                                            </table>
                                        </div>
                                        <hr/>
                                    </div>
                                    <span className="h12px"></span>
                                </div>
{/* Menu PROF / edit eleve */}                                
                                <div className={'fdc h100pc ' +this.state.displayeleveedit}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i> Cahier du jour de  {this.state.eleve.login === undefined ? '':this.state.eleve.login}</div>
                                    <EditEleve  parentMethod={this.apiGetEleves} eleve={this.state.eleve} />
                                    <span className="h12px"></span>
                                </div>
{/* Menu PROF / display/edit exo*/}
                                <div className={'fdc h100pc ' +this.state.displayexoedit}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i>  {this.state.exo.texte === undefined ? 'titre': this.state.exo.texte.split(',')[1]}</div>
                                    <div class='w100 text-right'>Niveau: {this.state.exo.texte === undefined ? 'titre': this.state.exo.texte.split(',')[2]} 
                                                               , {this.state.exo.texte === undefined ? 'titre': this.state.exo.texte.split(',')[0]}</div>
                                    <div className="h65vh" ><iframe src={this.state.iframeexo} height='100%'  width='100%' frameBorder="0"></iframe></div>
                                </div>

{/* Menu PROF / PARTIE display */}
                                <div className={'fdc h100pc ' +this.state.displaypartie}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i>Partie</div>
                                    <div className="h75vh" ><iframe src={this.state.iframepartie} height='100%'  width='100%' frameBorder="0"></iframe></div>
                                </div>
{/* Menu PROF / PARTIE add */}                                
                                <div className={'fdc h100pc ' +this.state.displaypartieadd}>
                                    <div className="maintitre"><i class="fas fa-chess-king"></i>Ajouter </div>
                                    <AddPartie  parentMethod={this.refreshParties} groupes={this.state.groupes}  eleves={this.state.eleves}    id={this.state.id}/>
                                </div>
                            </div>
                        
                        </div>
                        <div className={this.state.col8}> 
                            <div className={'row '+this.state.displayrow1}>
{/* les videos */}                            
                                <div className={'text-center col-sm '+this.state.displaycol1}>
                                    <div className="bordure bordermobile m2  fondblanc fdc maxh75vh">
                                        <div className="maxh60vh">
                                            <div  className='souligne fdr flexjustifycontentspace '>   
                                                    <div className="maintitre" id="1"     onClick={this.handleShowBox}><i class="fas fa-chess-pawn"></i> Les vidéos </div>
                                                    <BlocVideo myClassName="amande pointer"  fontawesome="info" titre="Plus d'infos" lien="https://youtu.be/veylzl_Jq3Q#t=1m20s" retour="/main"/>    
                                            </div>
                                            {/*this.state.numBox === 0 ? <ul className="text-left m2" >{this.renderVideo}<li id="1"  onClick={this.handleShowBox}>...</li></ul> : <ul>{this.renderVideoAll}</ul> */}
                                            {this.state.numBox === 0 ? <span><ul className="text-left m2" >{this.renderVideo}</ul></span> : <ul>{this.renderVideoAll}</ul> }
                                        </div>    
                                        <span className={'fa fa-times-circle rondclose '+this.state.displayretour}   onClick={this.handleShowRetour}></span>
                                        {this.state.numBox==0?<div className="text-right"><font className="m2px violet pointer" id="1"  onClick={this.handleShowBox}> Voir toutes les vidéos</font><i class="fa fa-chevron-circle-right rondplus" id="1"  onClick={this.handleShowBox}></i></div>:""}
                                    </div>
                                </div>
                                <div className={'col-sm '+this.state.displaycol2}>
{/* les classes */}                                                            
                                    <div className="bordure bordermobile m2  fondblanc fdc maxh75vh" >

                                        <div  className='souligne fdr flexjustifycontentspace '>   
                                                <div className="maintitre" id="2"     onClick={this.handleShowBox}><i class="fas fa-chess-bishop"></i> Les classes </div>
                                                <BlocVideo myClassName="amande pointer"  fontawesome="info" titre="Plus d'infos" lien="https://youtu.be/veylzl_Jq3Q#t=3m05s" retour="/main"/>    
                                        </div>

                                        <div className="maxh60vh">
                                            <ul className="m2">{this.state.groupes.map((groupe) => <li id={'groupe|'+groupe.id}  onClick={this.editRecord}  className={this.state.numBox == 0  ? "nodisplaya5": ""}>{groupe.nom}</li>) }</ul>
                                        </div>    
                                        <div className="text-center">
                                            <span  id="groupe" className={'rond m2 '+this.state.displayretour + ' '}onClick={this.addRecord}>  <i class="fas fa-plus-circle"></i>  Ajouter </span>   
                                        </div>
                                        <span className={'fa fa-times-circle rondclose '+this.state.displayretour}   onClick={this.handleShowRetour}></span>
                                        {this.state.numBox==0?<div className="text-right"><font className="violet m2px pointer" id="2"  onClick={this.handleShowBox}> Voir toutes les classes</font><i class="fa fa-chevron-circle-right rondplus" id="2"  onClick={this.handleShowBox}></i></div>:""}
                                    </div>
                                </div>  
                            </div>
                        
                            <div className={'row ' +this.state.displayrow2}>
{/* les éléves */}                                                        
                                <div className={'col-sm '+this.state.displaycol1}>
                                    <div className="bordure bordermobile m2  fondblanc fdc maxh75vh">
                                        <div  className='souligne fdr flexjustifycontentspace '>   
                                                <div className="maintitre" id="3"     onClick={this.handleShowBox}><i class="fas fa-chess-knight"></i> Les élèves </div>
                                                <BlocVideo myClassName="amande pointer"  fontawesome="info" titre="Plus d'infos" lien="https://youtu.be/veylzl_Jq3Q#t=3m57s" retour="/main"/>    
                                        </div>
                                        <div className="maxh60vh">
                                            {this.state.eleves.length === 0 ? 'Aucun élève actuellement':''}
                                            <ul className="m2 ba">
                                                    {this.state.eleves.map((eleve) => <li  className={this.state.numBox == 0  ? "nodisplaya5": ""}>
                                                    <table width='100%' border='0'><tr  >
                                                        <td id={'eleve|'+eleve.idEleve}  onClick={this.editRecord} align="left"> 
                                                            {eleve.statut==='OK' || eleve.statut===''
                                                                ?<i id={'eleve|'+eleve.idEleve}  class="fas fa-check"></i> 
                                                                :<i id={'eleve|'+eleve.idEleve}  class="fas fa-times-circle"></i> }
                                                        &nbsp;{eleve.login} </td>
                                                        <td id={'eleve|'+eleve.idEleve}  onClick={this.editRecord}  align="right"> {eleve.groupeLibelle}</td>
                                                        </tr></table>
                                                    </li>) }    
                                            </ul>
                                        </div>    
                                        <span className={'fa fa-times-circle rondclose '+this.state.displayretour}   onClick={this.handleShowRetour}></span>
                                        {this.state.numBox==0?<div className="text-right"><font className="m2px violet pointer" id="3"  onClick={this.handleShowBox}> Voir tous les élèves</font><i class="fa fa-chevron-circle-right rondplus" id="3"  onClick={this.handleShowBox}></i></div>:""}
                                    </div>
                                </div>
{/* les exercices */}                                                            
                                <div className={'col-sm '+this.state.displaycol2}>
                                    <div className="bordure bordermobile m2  fondblanc fdc maxh75vh" >

                                        <div  className='souligne fdr flexjustifycontentspace '>   
                                                <div className="maintitre" id="4"     onClick={this.handleShowBox}><i class="fas fa-chess-rook"></i> Les exercices </div>
                                                <BlocVideo myClassName="amande pointer"  fontawesome="info" titre="Plus d'infos" lien="https://youtu.be/veylzl_Jq3Q#t=5m11s" retour="/main"/>    
                                        </div>
                                        <div  className={this.state.numBox == 0  ? "nodisplay": "w100 text-right"} >
                                            <select   className="blocOrange"   name="filtreClasse"  onChange={this.myChangeHandler}  >
                                                <option value="..."> Voir toutes les classes</option>
                                                {this.state.groupes.map((groupe) => <option value={groupe.nom}>Filtre sur la classe {groupe.nom}</option> ) }
                                            </select>
                                        </div>
                                        <div className="maxh60vh" >
                                            <ul className="m2">
                                                    {
                                                        this.state.exos.map((exo) => <li  className=
                                                        {this.state.numBox == 0  
                                                            ? "nodisplaya3"
                                                            : this.state.filtreClasse !== '...' && this.state.filtreClasse !== exo.libClasse  ? 'nodisplay' :''
                                                        }
                                                    >
                                                    <table width='100%'><tr   >
                                                        <td  id={'exo|'+exo.id}  onClick={this.editRecord}>
                                                        {exo.libClasse}, {exo.texte}  {exo.type}   
                                                        </td >
                                                        <td   id={'exo|'+exo.id}  onClick={this.editRecord} className={this.state.numBox == 0  ? "nodisplay": ""}    align="right">
                                                            du {this.formatDate(exo.dateDebut)}
                                                            au {this.formatDate(exo.dateFin)}
                                                        </td>
                                                        <td  className={this.state.numBox == 0  ? "nodisplay": ""}    align="right">
                                                            <i class="fas fa-trash-alt"  id={'exo|'+exo.idGroupeExercice+'|delete'}  onClick={this.editRecord} ></i>
                                                        </td>
                                                        </tr></table>
                                                    </li>) }    
                                            </ul>
                                        </div>    
                                        <div className="text-center">
                                            <br className={this.state.displayretour} />            
                                            <span  id="exo"       className={'rond m2 '+this.state.displayretour + ' '}onClick={this.addRecord}>   <i class="fas fa-plus-circle"></i>  Ajouter </span> 
                                            <span  id="removeexo" className={'rond m2 '+this.state.displayretour + ' '}onClick={this.removeRecord}><i class="fas fa-minus-circle"></i>  Supprimer </span>     
                                            <span                 className={'rond m2 '+this.state.displayretour + ' '}onClick={this.apiGetExos}>  <i class="fa fa-refresh"></i>  Actualiser </span>                     

                                        </div>
                                        <span className={'fa fa-times-circle rondclose '+this.state.displayretour}   onClick={this.handleShowRetour}></span>
                                        {this.state.numBox==0?<div className="text-right"><font className="m2px violet pointer" id="4"  onClick={this.handleShowBox}> Voir tous les exercices</font><i class="fa fa-chevron-circle-right rondplus" id="4"  onClick={this.handleShowBox}></i></div>:""} 

                                    </div>
                                </div>  
                            </div>

                            <div className={'row ' +this.state.displayrow3}>
{/* les parties */}                                                        
                                <div className={'col-sm '+this.state.displaycol1}>
                                    <div className="bordure bordermobile m1  fondblanc fdc maxh75vh">

                                        <div  className='souligne fdr flexjustifycontentspace '>   
                                                <div className="maintitre" id="5"     onClick={this.handleShowBox}> <i class="fas fa-chess"></i> Les parties </div>
                                                <BlocVideo myClassName="amande pointer"  fontawesome="info" titre="Plus d'infos" lien="https://youtu.be/veylzl_Jq3Q#t=9m14s" retour="/main"/>    
                                        </div>


                                        <div className="maxh60vh">
                                            {this.state.parties.length === 0 ? 'Aucune partie actuellement':''}
                                            <ul className="ba">
                                                    {this.state.parties.map((partie) => <li  className={this.state.numBox == 0  ? "nodisplaya5": ""}>
                                                    <table width='100%'>
                                                        <thead><td colspan="3"></td></thead>
                                                        <tr>
                                                        <td width="41%" id={'partie|'+partie.id}  onClick={this.editRecord}>{partie.libEquipeBlanche}</td>
                                                        <td width="41%" id={'partie|'+partie.id}  onClick={this.editRecord}>{partie.libEquipeNoire}</td>
                                                        <td width="18%" id={'partie|'+partie.id}  onClick={this.editRecord}>{partie.statut}</td>
                                                        
                                                        </tr></table>
                                                    </li>) }    
                                            </ul>
                                        </div>
                                        <div className="text-center">
                                            <br className={this.state.displayretour} />                        
                                            <span  id="partie" className={'rond '+this.state.displayretour+' '} onClick={this.addRecord}><i class="fas fa-plus-circle"></i> Ajouter</span>   
                                        </div>
                                        <span className={'fa fa-times-circle rondclose '+this.state.displayretour}   onClick={this.handleShowRetour}></span>
                                        {this.state.numBox==0?<div className="text-right"><font className="m2px violet pointer" id="5"  onClick={this.handleShowBox}> Voir toutes les parties</font><i class="fa fa-chevron-circle-right rondplus" id="5"  onClick={this.handleShowBox}></i></div>:""}                
                                    </div>
                                </div>
                                 {/*
                                <div className={'col-sm '+this.state.displaycol2}><div className="bordure bordermobile m2  fondblanc h100pc">
                                        <div  id="6" className={'text-right '+this.state.displaytitre}   onClick={this.handleShowBox}>    > Gestion des parties individuel</div>
                                        <div         className={'text-right '+this.state.displayretour}  onClick={this.handleShowRetour}> Retour</div>
                                        Consultationdes parties individuel
                                </div></div>  
                                 */}
                            </div>
                        </div>
                    </div>
                </div>

               </div> 
            </div>
        )
    }
}
 