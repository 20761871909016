import React from 'react'
import Message from '../factory/Message'
import * as BOXApi from "../factory/BoxApi";
import '../factory/factory.css';

export default  class EditClasse  extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      ecShow        : true,
      ecId          : '',
      nom           : '',
      messageTexte  : '',
      messageTemps  : 0,
      messageStyle  : "info",
      redirectToHome: false  };
  }
 
  myChangeHandler = (event) => {
    debugger
    if(event.target.name === "nom"){
       this.setState({nom: event.target.value}); 
      }
  }

  errorSubmit = (error) =>{
    //alert('error ' + error)
    if(error  ){
      this.setState(() => ({ messageTexte: "Ooops, Erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
  }

  submitChange = (event) => {
    event.preventDefault();
    this.setState(() => ({ message: ""}))
    if(this.state.nom === ''){
      this.setState(() => ({ messageTexte: "Ooops, Nom obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    console.log("trt change Groupe " + BOXApi.profSaveGroupeXUrl());
    fetch(BOXApi.profSaveGroupeXUrl(), BOXApi.profSaveGroupeXRequest('EDIT',this.props.id,this.state.nom))
    .then(response => response.text())
    .then(result => this.submitChangeOK(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }

  submitDelete = (event) => {
    event.preventDefault();
    this.setState(() => ({ message: ""}))
    console.log("trt delete Groupe " + BOXApi.profSaveGroupeXUrl());
    fetch(BOXApi.profSaveGroupeXUrl(), BOXApi.profSaveGroupeXRequest('DELETE',this.props.id,''))
    .then(response => response.text())
    .then(result => this.submitDeleteOK(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }

  submitChangeOK = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json.response  && json.response.codeRetour && json.response.codeRetour === "OK"){
       this.setState(() => ({messageTexte: "modif réalisée " , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } ));
       sessionStorage.setItem('sessionGroupes', JSON.stringify(json.groupes));
       this.props.parentMethod();
    }else{
      this.setState(() => ({ messageTexte: "Ooops, Erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
  }

  submitDeleteOK = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json.response  && json.response.codeRetour && json.response.codeRetour === "OK"){
       this.setState(() => ({ ecShow:false, messageTexte: "classe " + this.state.nom + " supprimée." , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } ));
       sessionStorage.setItem('sessionGroupes', JSON.stringify(json.groupes));
       this.props.parentMethod();
    }else{
      this.setState(() => ({ messageTexte: "Ooops, Erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
  }



  render() {
    return (
        <div className="fondblanc fdc" >


        {this.state.ecId === this.props.id ? '': this.setState(() => ({ ecShow:true, ecId: this.props.id,nom:this.props.nom  })) }
            {this.state.ecShow
              ?<form onSubmit={this.mySubmitHandler}>
                <div className="m2 text-left">
                    Nom de la classe {/*this.props.id*/}
                </div>
                <div className="m2">
                  <input className="w100" type="text" name="nom"   onChange={this.myChangeHandler} value={this.state.nom} />
                </div>
                <br/><br/>
                <span className={'text-center rond m2'}  onClick={this.submitDelete}> <i class="fas fa-trash-alt"></i> Supprimer</span>  <span></span>   
                <span className={'text-center rond m2'}  onClick={this.submitChange}> <i class="fas fa-save"></i> Save</span>       
              </form>
            :''}
            <div>
            <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
             </div> 
            <div>
            </div>
            <hr/>
      </div>
    )
  }
}
