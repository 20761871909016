import React from 'react'
import { Link } from 'react-router-dom'
import * as BOX from "../factory/Box";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../factory/img/logo.png';

export default function HeaderPage() {
  return (

    <div>
      <div className="formatnormal fondblanc ombreb">
        <Link className="logo"  to="/"> 
          <img src={logo} width="50" height="50"></img>
          <span className="logopng00"> </span> {BOX.NOM_SITE} </Link>&nbsp; 
      </div>

      <div className="formatmobile fondblanc ombreb">
        <Link className="logo"  to="/"> <span className="logopng00"> </span> {BOX.NOM_SITE} </Link>
        <hr/>
      </div>

    </div>



  )
}
