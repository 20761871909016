import React from 'react'
import Message from '../factory/Message'
import * as BOXSession from "../factory/BoxSession";
import * as BOXApi from "../factory/BoxApi";
import '../factory/factory.css';

export default  class EditEcole  extends React.Component {

  constructor(props) {
    super(props);
    this.submitChange    = this.submitChange.bind(this);
    this.state = { 
      ecShow        : true,
      synchro       : 'true',
      ecolenom      : '',
      ecolecp       : '',
      ecoleville    : '',
      statutSelect  : '',
      messageTexte  : '',
      messageTemps  : 0,
      messageStyle  : "info",
      redirectToHome: false  };
  }
 
  myChangeHandler = (event) => {
    //if(this.state.synchro === "true")    {this.setState({synchro:'false', ecolecp: this.props.ecolecp, ecolenom: this.props.ecolenom, ecolecp: this.props.ecolecp, ecoleville: this.props.ecoleville, statutSelect: this.props.statutSelect    });}  
    if(event.target.name === "ecolenom")    {this.setState({synchro:'false', ecolenom: event.target.value});}
    if(event.target.name === "ecolecp")     {this.setState({synchro:'false', ecolecp: event.target.value});}
    if(event.target.name === "ecoleville")  {this.setState({synchro:'false', ecoleville: event.target.value});}
    if(event.target.name === "statutSelect"){this.setState({synchro:'false', statutSelect: event.target.value});}
  }

  errorSubmit = (error) =>{
    if(error  ){
      this.setState(() => ({ messageTexte: "Ooops, Erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
  }

  submitChange = (event) => {
    console.log("trt save Ecole " + this.props.ecolenom );
    event.preventDefault();
  if(this.state.ecolenom === ''){
    this.setState(() => ({ messageTexte: "Ooops, Nom obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    return;
  }
    console.log("trt save Ecole " + BOXApi.profSaveEcoleUrl());
    debugger
    var req = {'eleve':{
                  'login'       :BOXSession.getJsonParse('sessionUser').login,
                  'ecolenom'    :this.state.ecolenom,
                  'ecolecp'     :this.state.ecolecp,
                  'ecoleville'  :this.state.ecoleville,
                  'statutSelect':this.state.statutSelect
              }}
    


    fetch(BOXApi.profSaveEcoleUrl(), BOXApi.profSaveEcoleRequest(req))
    .then(response => response.text())
    .then(result => this.submitChangeOK(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }


  submitChangeOK = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    //this.setState(() => ({ messageTexte: "Ooops, Erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    
    if(json  && json.codeRetour && json.codeRetour === "OK"){
       this.setState(() => ({messageTexte: "modif réalisée " , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } ));
       sessionStorage.setItem('sessionGroupes', JSON.stringify(json.groupes));
       let user = BOXSession.getJsonParse('sessionUser');
       user.ecolenom = this.state.ecolenom;
       user.ecolecp = this.state.ecolecp;
       user.ecoleville = this.state.ecoleville;
       user.statutSelect = this.state.statutSelect;
       sessionStorage.setItem('sessionUser',    JSON.stringify(user));

       //this.props.parentMethod();
    }else{
      this.setState(() => ({ messageTexte: "Ooops, Erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
    
  }

 


  render() {
    return (
        <div className="fondblanc fdc" >

        {this.state.synchro === 'true'
          ?this.setState(() => ({synchro: 'false',ecolenom: this.props.ecolenom, ecolecp: this.props.ecolecp, ecoleville: this.props.ecoleville, statutSelect: this.props.statutSelect  })) 
          :''}
        {/*this.state.ecId === this.props.id ? '': this.setState(() => ({ ecShow:true, ecId: this.props.id,nom:this.props.nom  })) */}
        {/*this.state.synchro  && this.state.login === this.props.login ? '': this.setState(() => ({ login: this.props.login  })) */}
            {this.state.ecShow
              ?<form onSubmit={this.mySubmitHandler}>
                <div className="m2 text-left">
                    {this.props.login}
                </div>
                <div className="m2 text-left">
                {this.props.email}
                </div>
                <hr/>
                <div className="m2 text-left">
                    Nom de la classe 
                </div>
                <div className="m2">
                  <input className="w100" type="text" name="ecolenom"   onChange={this.myChangeHandler} value={this.state.ecolenom} />
                </div>
                <div className="m2 text-left">
                    Code postal
                </div>
                <div className="m2">
                  <input className="w100" type="text" name="ecolecp"   onChange={this.myChangeHandler} value={this.state.ecolecp} />
                </div>
                <div className="m2 text-left">
                    Ville
                </div>
                <div className="m2">
                  <input className="w100" type="text" name="ecoleville"   onChange={this.myChangeHandler} value={this.state.ecoleville} />
                </div>
                <div className="m2 text-left">
                    Présent sur écran d'inscription 
                </div>
                <div className="m2">
                  <select className="w100 myform" name="statutSelect"  value={this.state.statutSelect}  onChange={this.myChangeHandler}>
                      <option value="Y">Oui</option>
                      <option value="N">Non</option>
                  </select>
                </div>
                <br/>
                <span className={'text-center rond m2'}  onClick={this.submitChange}> <i class="fas fa-save"></i> Save</span>       
              </form>
            :''}
            
            <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            
            
      </div>
    )
  }
}
