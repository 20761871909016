import React from 'react'
import {Link} from 'react-router-dom'
import {Redirect} from 'react-router-dom';
import HeaderPage from '../factory/HeaderPageSimple'
import ActivationPageDetail from './ActivationPageDetail'
import * as BOXApi from "../factory/BoxApi";

import '../factory/factory.css';
import './MainPage.css';


export default class ActivationPage extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state  = {screen: 'chargement', redirectToHome: false  };
    }

    componentDidMount() {
        console.log("traitement activation " + BOXApi.inscriptionUrl());
        fetch(BOXApi.inscriptionUrl(), BOXApi.inscriptionRequest(JSON.stringify(
              {"mode":"ACTIVATION","eleve":{
                "login": 'u='+this.props.match.params.user+'&hashcode='+this.props.match.params.hashcode+'&site=monecoledesechecs',
             }}))
        )
        .then(response => response.text())
        .then(result => this.submitOK(JSON.parse(result)))
        .catch(error => this.submitKO(error));
    }

    submitOK = (json) =>{
        console.log(">>> trt submit " + JSON.stringify(json))
        if(json.reponse  && json.reponse.codeRetour && json.reponse.codeRetour === "OK"){
          this.setState({screen: 'activationOK' });
        }    
    }

    submitKO = (error) =>{
        if(error  ){this.setState(() => ({ messageTexte: "Ooops, erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); }
        this.setState({redirectToHome: true});
    }
      

    render() {
    if (this.state.redirectToHome) {  return <Redirect to='/home' />   }
    return (
        <div className="h100vh fondimage">
            <HeaderPage/>
            <br/><br/>
            <div className="container">
                <ActivationPageDetail screen={this.state.screen}/>
            </div>
        </div>
    )
  }
}  