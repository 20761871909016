import React from 'react'
import HeaderPage from '../factory/HeaderPageSimple'
import Form       from './ContactPageForm'


export default function ContatctPage() {
  return (
    <div className="h100vh fondimage">
        <HeaderPage/>
        <br/><br/>
        <div className="container">
            <Form />
        </div>
    </div>
  )
}
