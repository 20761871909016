import React from 'react'
import {Redirect} from 'react-router-dom';
import '../factory/factory.css';
import '../factory/animate.css';

export default  class HomeMobile  extends React.Component {
  constructor(props) {
    super(props);
    this.handleShowVideo = this.handleShowVideo.bind(this);
    this.state = {
      compteur: 1,
      titre: 'une bibliothèque de vidéos',
      texte: 'pour apprendre le mouvement des pièces et différents concepts du jeu d\'échecs.',
      titreyoutube:'Présentation du module des vidéos.',
      titreyoutube:    'Présentation du module des vidéos.',
      lien:            'https://youtu.be/veylzl_Jq3Q#t=1m37s',
      redirectToVideo: false
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(),5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    if(this.state.compteur === 3){
      this.setState({
        compteur: 1,
        titre: 'une bibliothèque de vidéos',
        texte: 'pour apprendre le mouvement des pièces et différents concepts du jeu d\'échecs.',
        titreyoutube:'Présentation du module des vidéos.',
        lien:        'https://youtu.be/veylzl_Jq3Q#t=1m37s'
  
      });
      return;      
    }
    if(this.state.compteur === 2){
      this.setState({
        compteur: 3,
        titre: 'un support matériel',
        texte: 'pour jouer collectivement ou individuellement des parties.',
        titreyoutube:'Présentation du module des parties.',
        lien:        'https://youtu.be/veylzl_Jq3Q#t=9m18s'
  
      });      
      return;      
    }

    if(this.state.compteur === 1){
      this.setState({
        compteur: 2,
        titre: 'un support pédagogique',
        texte: 'Où chaque enseignant peut planifier des exercices pour sa classe.',
        titreyoutube:'Présentation du module des exercices.',
        lien:        'https://youtu.be/veylzl_Jq3Q#t=5m09s'

  
      });      
      return;      
    }
  }


  handleShowVideo() {
    console.log("test");
    sessionStorage.setItem('sessionVideoLien',   this.state.lien);
    sessionStorage.setItem('sessionVideoRetour', '/home');
    sessionStorage.setItem('sessionVideoTitre',  this.state.titreyoutube);
    this.setState(() => ({ redirectToVideo: true }))
}





  start(){
      //console.log('Start')
      if(this.state.interval !==0){
        //console.log('process ' + this.state.interval + ' en cours,  on le delete')
        clearInterval(this.state.interval)
        this.removeClasse();
      }
      //console.log('calcul temps affcihage')
      let now   = Date.now()
      let temps = Number(this.props.temps) - Number(now) 
      //console.log('temps affichage ' + temps)
      const interval = setInterval(() => {
        this.end();
      }, temps);
      //console.log('process ' + interval + ' started')
      this.setState(()=>({interval: interval,temps:this.props.temps,texte:this.props.texte }))    
      this.addClasse()
    }

  end(){
      //console.log("reveil fin process " + this.state.interval + ', on le delete')
      clearInterval(this.state.interval)
      this.removeClasse();
      this.setState(()=>({interval: 0,texte:'' }))    
  }

  addClasse(){
      //console.log('On ajoute animated')  
      var element = document.getElementById("message");
      element.classList.add("animated");
      element.classList.add("fadeInLeft");
  }

  removeClasse(){
      //console.log('On remove animated')  
      var element = document.getElementById("message");
      element.classList.remove("animated");
      element.classList.remove("fadeInLeft");
  }
   
  render() {
    if (this.state.redirectToVideo) {  return <Redirect to='/video' />   }
    return (
      <div className="homephotomobile">
        <div className="container text-center"> 
          <div className="row homephotomobilepolice text-left">
            C'est {this.state.titre} <br/>
          </div>
          <div className="row homephotomobilepolice2 text-left">  
          {this.state.texte}
          </div>
          <br/>
          <div>
            <span className={'text-center rond m2'} onClick={this.handleShowVideo}>...  EN SAVOIR PLUS ?  </span>
          </div>
        </div>
        <br/>
   
    </div>
    )
  }
}
