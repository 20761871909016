import React from 'react'
import '../factory/factory.css';
import Message from './Message'

export default  class TestClasse  extends React.Component {
  constructor(props) {
    super(props);
      this.change         = this.change.bind(this);
      this.submit         = this.submit.bind(this);
      this.state = { 
        nom           : '',
        messageTexte  : '',
        messageTemps  : 0,
        messageStyle  : "info",
    }
  }
 

  change = (event) => {
    if(event.target.name === "nom"){ this.setState({nom: event.target.value}); }
  }

  submit = (event) => {
    event.preventDefault();
    this.setState(() => ({ message: ""}))
    this.setState(() => ({ messageTexte: "Enregistre ajouté " + this.state.nom , messageTemps : Number(Date.now()+5*1000)  } ));
  }
  

  render() {
    return (
        <div className="fondblanc fdc" >
            Mise à jour classe: <br/>
            <form onSubmit={this.submit}>
              <div className="m2">
                  Nom
              </div>
              <div className="m2">
                <input className="w100" type="text" name="nom"   onChange={this.change} value={this.props.nom} />
              </div>
              <div className="m5 text-center">
                <input className="btn btn-secondary" type="submit" value="Enregistrer" />
              </div>        
            </form>
            <div className="text-center">

              <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            </div>
      </div>
    )
  }
}
