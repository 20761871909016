import React from 'react'
import Message from '../factory/Message'
import * as BOXApi from "../factory/BoxApi";
import * as BOXSession from "../factory/BoxSession";
import '../factory/factory.css';
import good from '../factory/img/good.png';
import nogood from '../factory/img/nogood.png';

export default  class EditEleve  extends React.Component {

  constructor(props) {
    super(props);
    this.fetchExos       = this.fetchExos.bind(this);
    this.state = { 
      test          : '', 
      exos          : [],
      messageTexte  : '',
      messageTemps  : 0,
      messageStyle  : "info",
      redirectToHome: false  };
  }


  shouldComponentUpdate(nextProps, nextState) {
      console.log(" shouldComponentUpdate nextProps " + JSON.stringify(nextProps));
      if(nextProps.eleve.login !== undefined   ){
        console.log(" recherche des exercices ");
        this.fetchExos(nextProps.eleve );
      }
      return true;    
  }

  fetchExos = (eleve ) => {
    console.log('cchargement des exos de ' + JSON.stringify(eleve))
    if(BOXSession.getJsonParse('sessionUserDisplay').login === eleve.login ){
      //this.setState(() => ({ test: eleve.login  } )); 
      return ;
    }
    sessionStorage.setItem('sessionUserDisplay',    JSON.stringify(eleve));
    //sessionStorage.setItem('sessionUserExosDisplay',   []);
    console.log("trt fetch Exos  " + BOXApi.profGetUserExoUrl(eleve.idEleve));
    fetch(BOXApi.profGetUserExoUrl(eleve.idEleve), BOXApi.profGetUserExoRequest())
    .then(response => response.text())
    .then(result => this.submitOK(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }

  errorSubmit = (error) =>{
    //alert('error ' + error)
    if(error  ){
      this.setState(() => ({ messageTexte: "Ooops, Erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
    //sessionStorage.setItem('sessionUserExosDisplay',    []);
  }
 

  

  submitOK = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    sessionStorage.setItem('sessionUserExosDisplay',    JSON.stringify(json.exercices));
    //this.setState(() => ({ exos:json.exercices  , messageTexte: "..." + (new Date()).toLocaleString()   , messageStyle : 'info', messageTemps : Number(Date.now()+3*1000)  } )); 
    this.setState(() => ({ exos:json.exercices   } )); 
    /*
    if(json.response  && json.response.codeRetour && json.response.codeRetour === "OK"){
       this.setState(() => ({messageTexte: "modif réalisée " , messageStyle : 'info', messageTemps : Number(Date.now()+3*1000)  } ));
       sessionStorage.setItem('sessionGroupes', JSON.stringify(json.groupes));
       this.props.parentMethod();
    }else{
      this.setState(() => ({ messageTexte: "Ooops, Erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
    */
  }

  formatDate(date){
    return new Date(date).toLocaleDateString() 
}


render() {
  return (
    <div className="fondblanc fdc" >
        {/*{eleve.statut==='OK' || eleve.statut===''*/}
        {/*this.state.test} ** { BOXSession.getJsonParse('sessionUserDisplay').login */} 
        {/*BOXSession.getJsonParse('sessionUserExosDisplay')  ===  []
          ? 'ok'
          : 'Chargement'
  */}
 


        <div class='w100 text-left m5px'>
           <table width="100%">
                <thead><td></td><td></td></thead>
                <tr><td>Login:</td> <td>{this.props.eleve.login}</td>  </tr>
                <tr><td>Password: </td> <td>{this.props.eleve.pwd}</td>  </tr>
                <tr><td>Emai:</td> <td>{this.props.eleve.email}</td>  </tr>
                <tr><td>Classe:</td><td>{this.props.eleve.groupeLibelle}</td>  </tr>
                <tr><td>Nombre d'exo: </td><td>{this.props.eleve.nbExercice}</td>  </tr>
                <tr><td>Nombre de point:</td><td>{this.props.eleve.nbPoint}</td>  </tr>
                <tr><td>Etat:</td>  <td> {this.props.eleve.statut === 'OK' 
                    ? 'compte actif' 
                    : 'compte non validé'} 
                </td></tr>
            </table>
            <hr/>

            <div className={this.state.exos.length == 0  ? "nodisplay": ""} >
                    Les exercices:<br/>
                    <table width="100%">
                      {/*BOXSession.getJsonParse('sessionUserExosDisplay').map((exo) => <tr><td>{exo.question}</td>  </tr>)*/}
                      {this.state.exos.map((exo) => <tr>
                                                        <td>{this.formatDate(exo.dateRealisation)} {/*exo.question*/} {exo.theme}</td> 
                                                        <td width="right">
                                                        


                                                        {exo.statut==='DO_1_1'?<img src={good} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_1_1'?<img src={good} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_1_1'?<img src={good} width="20" height="20"></img>:''} 

                                                        {exo.statut==='DO_1_2'?<img src={nogood} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_1_2'?<img src={good} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_1_2'?<img src={good} width="20" height="20"></img>:''} 

                                                        {exo.statut==='DO_1_3'?<img src={nogood} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_1_3'?<img src={nogood} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_1_3'?<img src={good} width="20" height="20"></img>:''} 

                                                        {exo.statut==='DO_0_1'?<img src={nogood} width="20" height="20"></img>:''} 
                                                        
                                                        {exo.statut==='DO_0_2'?<img src={nogood} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_0_2'?<img src={nogood} width="20" height="20"></img>:''} 

                                                        {exo.statut==='DO_0_3'?<img src={nogood} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_0_3'?<img src={nogood} width="20" height="20"></img>:''} 
                                                        {exo.statut==='DO_0_3'?<img src={nogood} width="20" height="20"></img>:''} 

                                                        </td> 
                                                  </tr>)}
                    </table>
                    <hr/>
              </div> 
        </div>   
        <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
      
    </div>
    )
  }
}
