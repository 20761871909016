import React from 'react'
import Message from '../factory/Message'
import * as BOXApi     from "../factory/BoxApi";
import * as BOXSession from "../factory/BoxSession";
import '../factory/factory.css';

export default  class AddPartie  extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      joueur1type   : '...',
      joueur1classe : '...',
      joueur1eleve  : '...',
      joueur2type   : 'pgm',
      joueur2eleve  : '...',
      joueur2classe : '...',
      messageTexte  : '',
      messageTemps  : 0,
      messageStyle  : "info",
      redirectToHome: false  };
  }

  myChangeHandler = (event) => {
    if(event.target.name === "joueur1type"){ 
      this.setState({joueur1type: event.target.value,joueur1classe: '...',joueur1eleve: '...' }); 
      //if(event.target.value === "classe"){ 
      //  document.getElementById("classe").focus();
      //}
    }
    if(event.target.name === "joueur1classe"){this.setState({joueur1classe: event.target.value }); }
    if(event.target.name === "joueur1eleve") {this.setState({joueur1eleve: event.target.value }); }
    if(event.target.name === "joueur2type")  {this.setState({joueur2type: event.target.value,joueur2classe: '...',joueur2eleve: '...' });    }
    if(event.target.name === "joueur2classe"){this.setState({joueur2classe: event.target.value }); }
    if(event.target.name === "joueur2eleve") {this.setState({joueur2eleve: event.target.value }); }
  }

  mySubmitHandler = (event) => {
    debugger;
    console.log(this.props.eleves);
    event.preventDefault();
    this.setState(() => ({ message: ""}))
    if(this.state.joueur1type === '...'){
      this.setState(() => ({ messageTexte: "Ooops, Choisir classe ou éléve pour le joueur 1 !!!" , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    if(this.state.joueur1type === 'classe' && this.state.joueur1classe === '...' ){
      this.setState(() => ({ messageTexte: "Ooops, Choisir une classe pour le joueur 1 !!!" , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    if(this.state.joueur1type === 'eleve' && this.state.joueur1eleve === '...' ){
      this.setState(() => ({ messageTexte: "Ooops, Choisir un élève pour le joueur 1 !!!" , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    if(this.state.joueur2type === 'classe' && this.state.joueur2classe === '...' ){
      this.setState(() => ({ messageTexte: "Ooops, Choisir une classe pour le joueur 2 !!!" , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    if(this.state.joueur2type === 'eleve' && this.state.joueur2eleve === '...' ){
      this.setState(() => ({ messageTexte: "Ooops, Choisir un élève pour le joueur 2 !!!" , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }
    if(  this.state.joueur1type === 'classe' 
      && this.state.joueur2type === 'classe' 
      && this.state.joueur1classe === this.state.joueur1classe  ){
        this.setState(() => ({ messageTexte: "Ooops, Choisir 2 classes différentes !!!" , messageStyle : 'infoOrange', messageTemps : Number(Date.now()+3*1000)  } )); 
      return;
    }


    console.log("trt ajout new game " + BOXApi.profSaveNewPartieUrl());
    let req = {"mode": this.state.joueur1type+"|"+this.state.joueur2type,
                "partie":   {
                  "idEquipeBlanche":this.state.joueur1type === 'classe' ? this.state.joueur1classe : '' ,
                  "idEquipeNoire":  this.state.joueur2type === 'classe' ? this.state.joueur2classe : '' ,
                  "idBlanc":        this.state.joueur1type === 'eleve' ? this.state.joueur1eleve : '' ,
                  "idNoir":         this.state.joueur2type === 'eleve' ? this.state.joueur2eleve : '' ,
                  "statut":         'En cours',
                  "leTrait":        'b',
                  "lesCoups":       '',
                  "prof":           BOXSession.getJsonParse('sessionUser').login,
                }
              };
    fetch(BOXApi.profSaveNewPartieUrl(), BOXApi.profSaveNewPartieRequest(req))
    .then(response => response.text())
    .then(result => this.trtSubmit(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
    
  }
  
  errorSubmit = (error) =>{
    alert('error ' + error)
    if(error  ){this.setState(() => ({ message: "Erreur réseau"  }))}
  }
  
  trtSubmit = (json) =>{
    debugger;
    console.log(">>> trt submit " + JSON.stringify(json))
    if(json  && json.reponse  && json.reponse.codeRetour && json.reponse.codeRetour === "OK"){
       this.setState(() => ({ messageTexte: 'Partie enregistrée', messageStyle : 'infoOrange',  messageTemps : Number(Date.now()+3*1000)  } ));
       sessionStorage.setItem('sessionParties', JSON.stringify(json.parties));
       this.props.parentMethod();
    }else{
      this.setState(() => ({ messageTexte: 'Erreur serveur', messageStyle : 'erreur',  messageTemps : Number(Date.now()+3*1000)  } ));
    }
  }


  render() {
    return (
        <div className="fondblanc " >
        
 
            <form onSubmit={this.mySubmitHandler}>
                <div className="m2 text-left">Joueur 1</div>
                <div className="m2">
                  <select className="w100 myform"  name="joueur1type"    onChange={this.myChangeHandler}>
                    <option value="...">Choisir une valeur</option>
                    <option value="classe">Classe</option>
                    <option value="eleve">Elève</option>
                  </select>
                </div>
                {this.state.joueur1type === '...'?<br/>:''}
                {this.state.joueur1type === 'classe'
                  ?<div className="m2">
                    <select className="w100 myform"  name="joueur1classe"  id="joueur1classe"     onChange={this.myChangeHandler}>
                      <option value="...">Choisir une valeur</option>
                      {this.props.groupes.map((groupe) => <option value={groupe.id}>{groupe.nom}</option> ) }
                    </select>
                  </div>
                  :''}
                {this.state.joueur1type === 'eleve'
                  ?<div className="m2">
                    <select className="w100 myform"  name="joueur1eleve"    onChange={this.myChangeHandler}>
                      <option value="...">Choisir une valeur</option>
                      {this.props.eleves.map((eleve) => <option value={eleve.idEleve}>{eleve.login}</option> ) }
                    </select>
                  </div>
                  :''}
                <div className="m2 text-left">Joueur 2</div>
                <div className="m2">
                  <select className="w100 myform"  name="joueur2type"    onChange={this.myChangeHandler}>
                    <option value="pgm">Attribution aléatoire</option>
                    <option value="classe">Classe</option>
                    <option value="eleve">Elève</option>
                  </select>
                </div>
                {this.state.joueur2type === 'pgm'?<br/>:''}
                {this.state.joueur2type === 'classe'
                  ?<div className="m2">
                    <select className="w100 myform"  name="joueur2classe"  id="joueur2classe"     onChange={this.myChangeHandler}>
                      <option value="...">Choisir une valeur</option>
                      {this.props.groupes.map((groupe) => <option value={groupe.id}>{groupe.nom}</option> ) }
                    </select>
                  </div>
                  :''}
                {this.state.joueur2type === 'eleve'
                  ?<div className="m2">
                    <select className="w100 myform"  name="joueur2eleve"    onChange={this.myChangeHandler}>
                      <option value="...">Choisir une valeur</option>
                      {this.props.eleves.map((eleve) => <option value={eleve.idEleve}>{eleve.login}</option> ) }
                    </select>
                  </div>
                  :''}
                
              <span className={'text-center rond m2'}  onClick={this.mySubmitHandler}> <i class="fas fa-save"></i> Enregistrer</span>
            </form>
            <div>
                       <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            </div>
            <br/>
            <hr/>
            
      </div>
    )
  }
}
