import React from 'react'
import {Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom'
import * as BOX from "./Box";
import * as BOXSession from "./BoxSession";
import '../factory/factory.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import left from '../factory/img/left.png';



export default  class HeaderPageConnect  extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      //sessionUser   :    BOXSession.getString('sessionUser'),
      sessionUser   :    BOXSession.getJsonParse('sessionUser').login ,
      sessionExos   :    BOXSession.getJsonParse('sessionExos'), 
      redirectToHome: false 
      };
    }
   
    componentDidMount() {
      console.log(">>HeaderPageConnect componentDidMount: "+this.state.sessionUser + "|" + BOXSession.getString('sessionUser'))
      //debugger
      if(this.state.sessionUser == null ){
        this.setState(() => ({ redirectToHome: true }))
      }   
     // if( this.state.sessionExos.constructor === Array  ){
     //   this.setState(() => ({ redirectToHome: true }))
     // }   

    }



  handleClick = () => {
    console.log(">>"+this.state.sessionUser)
    sessionStorage.clear();
    this.setState(() => ({ redirectToHome: true }))
  }

  render(){
    if (this.state.redirectToHome) {  return <Redirect to='/home' />   }
    return (
      <div className="fondblanc ombreb h53px">
        <Link className="retour"  to="/"> 
        <img src={left} width="30" height="30"></img> &nbsp;
         Retour </Link>&nbsp; 
        <div className="float-right m5px">
           {this.state.sessionUser} 
            &nbsp;&nbsp;&nbsp;&nbsp;

            <button className="rond m5" onClick={this.handleClick}>
            <i class="fas fa-sign-out-alt"></i> Se déconnecter
            </button>
            


        </div> 
       
    </div>
  )
}

}
 