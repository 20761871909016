import React from 'react'
import '../factory/factory.css';
import '../factory/animate.css';

export default  class MessageClasse  extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
            temps    : 0,
            texte  : '',
            style  : '',
            interval:0
       };
  }

  start(){
      //console.log('Start')
      if(this.state.interval !==0){
        //console.log('process ' + this.state.interval + ' en cours,  on le delete')
        clearInterval(this.state.interval)
        this.removeClasse();
      }
      //console.log('calcul temps affcihage')
      let now   = Date.now()
      let temps = Number(this.props.temps) - Number(now) 
      //console.log('temps affichage ' + temps)
      const interval = setInterval(() => {
        this.end();
      }, temps);
      //console.log('process ' + interval + ' started')
      this.setState(()=>({interval: interval,temps:this.props.temps,texte:this.props.texte }))    
      this.addClasse()
    }

  end(){
      //console.log("reveil fin process " + this.state.interval + ', on le delete')
      clearInterval(this.state.interval)
      this.removeClasse();
      this.setState(()=>({interval: 0,texte:'' }))    
  }

  addClasse(){
      //console.log('On ajoute animated')  
      var element = document.getElementById("message");
      element.classList.add("animated");
      element.classList.add("fadeInLeft");
  }

  removeClasse(){
      //console.log('On remove animated')  
      var element = document.getElementById("message");
      element.classList.remove("animated");
      element.classList.remove("fadeInLeft");
  }
   
  render() {
    return (
        <div>
          {(     this.props.texte 
              && this.props.temps 
              && this.props.temps !== this.state.temps)
                  ? this.start():''}
              <div id="message">
                <div   className={this.props.style}>
                      {this.state.texte}
                 </div>
              </div>   
      </div>
    )
  }
}
