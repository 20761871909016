import React from 'react'
import {Link} from 'react-router-dom'

//  Thank you for registering. Before you can login we need you to activate your account by clicking the activation link in the email we just sent you.
// Il faut faire entrer les échecs dans les écoles, ils donnent des outils dans la vie : la logique, la résilience, savoir trancher, accepter une défaite, maîtriser l’euphorie d’une victoire, ça rend plus fort.
// Quelqu'un qui sait jouer aux échecs, va savoir bien vivre. Car dans la vie, tout est stratégie, même préparer un plat de cuisine ! 




export default function ContactPageConfirm() {
  return (




    <div className="fdrr">
      <div className="fondblanc rw50 bordure animated fadeInLeft">
        <div className="w100 text-right">
            <Link  className="fa fa-times-circle rondclose" to="/login"></Link>&nbsp;
        </div>
        <div className="m2">
            NOUS AVONS ENVOYÉ VOTRE MESSAGE.
            <br/>
            <div className="fdr m2">
                  <div className="fondcolor"> &nbsp;<br/></div>
                  <div className="m2">
                    Merci de nous avoir contactés.<br/>
                    Nous essaierons de vous répondre aussi vite que possible.
                  </div>
            </div>
        </div>

      </div>

    </div>
  )
}
