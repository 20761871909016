
import React from 'react'
import {Link} from 'react-router-dom'
import '../factory/factory.css';
import './MainPage.css';


export default  function ActivationPageDetail(props) {
    if(props.screen === 'chargement') {
        return (
            <div className="fontblanc rw50 Xbordure animated bounce">
                <br/><br/><br/>
                <h1> chargement en cours ...</h1>
            </div>
        )
    }
    if(props.screen === 'activationOK') {
        return (
        <div className="fdrr">
            <div className="fondblanc rw50 bordure animated fadeInLeft">
                <div className="w100 text-right">
                    <Link  className="fa fa-times-circle rondclose" to="/login"></Link>&nbsp;
                </div>
                <div className="m2">
                    Votre compte est maintenant actif.
                    <br/>
                    <div className="fdr m2">
                            <div className="fondcolor"> &nbsp;<br/></div>
                            <div className="m2">
                            Bienvenue dans votre école des échecs.
                            </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    return (<div></div>)

}