import React from 'react'
import {Redirect} from 'react-router-dom';
import ContactPageConfirm from './ContactPageConfirm'
import Message from '../factory/Message'
//import * as BOXSession from "../factory/BoxSession";
import * as BOXApi from "../factory/BoxApi";
import '../factory/factory.css';
import '../factory/animate.css'; 

export default  class ContactForm  extends React.Component {
  constructor(props) {
    super(props);
    this.submitHandler = this.submitHandler.bind(this);
    this.email         = React.createRef();
    this.sujet         = React.createRef();
    this.message       = React.createRef();
    this.state = { 
      ecran2        : false,
      email         : '',
      sujet         : '',
      message       : '',
      messageTexte  : '',messageTemps:0,messageStyle:"info",
      redirectToHome: false  };
  }



  myChangeHandler = (event) => {
    if(event.target.name === "email")    { this.setState({email: event.target.value}); }
    if(event.target.name === "sujet")    { this.setState({sujet: event.target.value}); }
    if(event.target.name === "message")  { this.setState({message: event.target.value}); }
  }


  submitHome = (event) => {
    this.setState(() => ({ redirectToHome: true }))
  }


  submitHandler = (event) => {
    event.preventDefault();
    this.setState( {messageTexte: ''})
    console.log('trt submit' + this.state.messageTexte);
    if(this.state.email === '' )  {
      this.setState({ messageTexte: "Ooops, email obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
      this.email.current.focus();
      return;   
    }
    if(this.state.sujet === '' )  {
      this.setState({ messageTexte: "Ooops, sujet obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
      this.sujet.current.focus();
      return;   
    }
    if(this.state.message === '' )  {
      this.setState({ messageTexte: "Ooops, message obligatoire !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } );
      this.message.current.focus();
      return;   
    }


    
    console.log("traitement contact" + BOXApi.contactUrl());
    let body = JSON.stringify({"email":this.state.email,"sujet":this.state.sujet,"message":this.state.message});
    fetch(BOXApi.contactUrl(), BOXApi.contactRequest(body))
    .then(response => response.text())
    .then(result => this.trtSubmit(JSON.parse(result)))
    .catch(error => this.errorSubmit(error));
  }
  
  errorSubmit = (error) =>{
    if(error  ){
      this.setState(() => ({ messageTexte: "Ooops, erreur réseau !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    }
  }
  
  trtSubmit = (json) =>{
    console.log(">>> trt submit " + JSON.stringify(json))
    //if(json  && json.codeRetour && json.codeRetour === "OK"){
      // TRAITEMENT REPONSE SERVEUR OK
      this.setState(() => ({ ecran2: true }))
    //}else{
      // TRAITEMENT REPONSE SERVEUR KO
    //  try {
    //    this.setState(() => ({ messageTexte: 'Oooops '+json.reponse+' !!!' , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    //  } catch (error) {
    //    this.setState(() => ({ messageTexte: "Ooops, erreur serveur !!!" , messageStyle : 'erreur', messageTemps : Number(Date.now()+3*1000)  } )); 
    //  }
    //}
  }
 



  render() {
    if (this.state.redirectToHome) {  return <Redirect to='/home' />   }
    if (this.state.ecran2)         {  return <ContactPageConfirm/>   }
    return (
      <div className='text-center00'>
        <div className="fondblanc bordure fdc  animated pulse rw50" >
            <h4>Nous contacter</h4>
            Si vous avez la moindre question, problème ou suggestion, veuillez remplir notre formulaire de contact.
            <form className="m2" onSubmit={this.submitHandler}>
              <div className="text-left">{this.state.email === '' ? <br/> : 'Votre e-mail'}</div>
              <div className="">         <input className="w100" type="text" name="email"     ref={this.email}     onChange={this.myChangeHandler} placeholder="Votre e-mail"/></div>
              <div className="text-left">{this.state.sujet === '' ? <br/> : 'Sujet'}</div>
              <div className="">         <input className="w100" type="text" name="sujet"     ref={this.sujet}     onChange={this.myChangeHandler} placeholder="Sujet"/></div>
              <div className="text-left">{this.state.message === '' ?  'Rédigez votre message ici! (max. 500 caractères)' : 'Un message'}</div>
              
              <textarea  name="message" rows="8" cols="63"  ref={this.message}    onChange={this.myChangeHandler}>
                  
              </textarea>
              
              
              
              <br/><br className="formatnormal"/>

              <div className="row">
                <div className="col text-center">
                  <span  className={'text-center rondreverse m5 m2'}  onClick={this.submitHome}> Annuler </span>
                </div>
                <div className="col text-center ">
                  <span className={'text-center rond m5'}  onClick={this.submitHandler}> Valider </span>
                </div>
              </div>        
            </form>

            <div className="text-center">
                <Message texte={this.state.messageTexte} temps={this.state.messageTemps} style={this.state.messageStyle}/>
            </div>
        </div>
      </div>
    )
  }
}
