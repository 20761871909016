import React from 'react'
import {Redirect} from 'react-router-dom';
import ReactPlayer from "react-player"
import * as BOXSession from "../factory/BoxSession";
import './VideoPage.css';
import '../factory/factory.css';
 


export default class VideoPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        redirect           : false ,
        sessionVideoLien   : BOXSession.getString('sessionVideoLien'),  
        sessionVideoRetour : BOXSession.getString('sessionVideoRetour'),  
        sessionVideoTitre  : BOXSession.getString('sessionVideoTitre'),  
      };
      this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
      this.setState(() => ({ redirect: true }))
    }
    render() {
        if (this.state.redirect) {  return <Redirect to={this.state.sessionVideoRetour} />   }
        return (
            <div className="body">
                <div className="fondsousmenu text-center">
                    <div class="w95">
                        <div class="row align-items-center justify-content-center">
                                <div class="col-1 formatnormal" onClick={this.handleClick}> 
                                        <img class="img-fluid"  src="http://www.elearnchess.com/chessV2/static/img/blue-002-left-arrow.png" alt="icon"/>
                                </div>
                                <div class="col-2 formatmobile" onClick={this.handleClick}> 
                                        <img class="img-fluid"  src="http://www.elearnchess.com/chessV2/static/img/blue-002-left-arrow.png" alt="icon"/>
                                </div>
                                <div class="col-2 text-left" onClick={this.handleClick}>
                                        <div class="subtitle"><font color="#49349e">ECRAN</font></div>
                                        <div class="title"><font color="#49349e	">PRECEDENT</font></div>
                                </div>
                                <div class="col text-right" >
                                        <div class="w100">
                                            <h2 className="formatnormal">{this.state.sessionVideoTitre} </h2>
                                            <h7 className="formatmobile">{this.state.sessionVideoTitre} </h7>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="video">
                    <ReactPlayer 
                        url={this.state.sessionVideoLien}
                        playing={true}
                        width='100%'
                        height='100%'
                    />
                </div>
            </div> 
      )
    }
  }


 